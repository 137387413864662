import __request__ from 'routing/request';
interface ScanImportResult {
    readonly success: boolean;
    readonly message: string;
    readonly updated_assets: AssetDto[];
    readonly errors: string[];
}
interface ScannerTestResult {
}
interface Vulnerability {
    readonly severity: 'CRITICAL' | 'HIGH' | 'MEDIUM' | 'LOW' | 'INFO' | 'NONE';
    readonly cve: string;
    readonly vulnerability_id: string;
    readonly cvss: number;
}
interface ScheduledConfigView {
    readonly enabled: boolean;
    readonly interval_hours: number;
}
interface ScannerConfig {
    readonly type: string;
}
interface ClearVulnerabilitiesResponse {
    readonly assets_cleared: number;
    readonly assets_updated: number;
}
interface PortInfo {
    readonly protocol: string;
    readonly port: string;
}
interface ScannerView {
    readonly scheduled_config: ScheduledConfigView;
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly config: ScannerConfig;
}
interface PaginatedResponse_ScannerView {
    readonly [_key: string]: Object;
}
interface AssetDto {
    readonly risk_score_normalized: number;
    readonly rev: number;
    readonly source_asset_id: string;
    readonly risk_score: number;
    readonly imported_from: string;
    readonly event_definition_ids: string[];
    readonly created_at: string;
    readonly rev_date: string;
    readonly dn: string;
    readonly priority: number;
    readonly orig_id: string;
    readonly vulnerability_factor: number;
    readonly name: string;
    readonly highest_risk_score: number;
    readonly vulnerabilities: AssetVulnerabilities[];
    readonly details: AssetDetails;
    readonly id: string;
    readonly category: string[];
}
interface AssetVulnerabilities {
    readonly scanner_id: string;
    readonly scanner_type: string;
    readonly hostname: string;
    readonly os: string;
    readonly ip: string;
    readonly vulnerabilities: Vulnerability[];
    readonly scan_id: string;
    readonly scan_name: string;
    readonly ports: PortInfo[];
    readonly mac: string;
}
type stringArray = string[];
interface AssetDetails {
    readonly type: string;
}
/**
 * Get a paginated list of existing vulnerability scanners
 * @param sort The field to sort the result on
 * @param direction The sort direction
 */
export function paginated(sort: 'title' | 'description' = 'title', page: number = 1, per_page: number = 15, query?: string, filters?: string, direction: 'asc' | 'desc' = 'asc'): Promise<PaginatedResponse_ScannerView> {
    return __request__('GET', '/plugins/org.graylog.plugins.securityapp/vulnerability_scanners', null, { 'page': page, 'per_page': per_page, 'query': query, 'filters': filters, 'sort': sort, 'direction': direction }, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new vulnerability scanner
 */
export function create(JSONBody?: ScannerView): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/vulnerability_scanners', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test a vulnerability scanner connection
 */
export function testNewConnection(JSONBody?: ScannerView): Promise<unknown> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/test', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upload one or more vulnerability scan reports.
 */
export function upload(type: string): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/upload/${type}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get a vulnerability scanner by ID
 */
export function get(id: string): Promise<ScannerView> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Edit an existing vulnerability scanner
 */
export function update(id: string, JSONBody?: ScannerView): Promise<unknown> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${id}`, JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing vulnerability scanner
 */
export function remove(id: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Import vulnerability scans
 */
export function importScan(id: string): Promise<ScanImportResult> {
    return __request__('GET', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${id}/import`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Test connection to vulnerability scanner
 */
export function testConnection(id: string): Promise<ScannerTestResult> {
    return __request__('PUT', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${id}/test`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Clear all asset vulnerabilities detected by an existing scanner
 */
export function clear(scannerId: string, scan_ids?: stringArray, clear_checkpoint: boolean = false, clear_assets: boolean = false): Promise<ClearVulnerabilitiesResponse> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${scannerId}/clear`, null, { 'scan_ids': scan_ids, 'clear_checkpoint': clear_checkpoint, 'clear_assets': clear_assets }, {
        'Accept': ['application/json']
    });
}
/**
 * Schedule sync for an existing vulnerability scanner
 */
export function scheduleImport(scannerId: string, JSONbody: ScheduledConfigView): Promise<unknown> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/vulnerability_scanners/${scannerId}/schedule`, JSONbody, {}, {
        'Accept': ['application/json']
    });
}
