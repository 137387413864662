import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';

type Props = {
  fieldName: string,
  assetsOptions: Array<{ label: string, value: string }>,
}

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

const AssetsFilterFieldValue = ({ fieldName, assetsOptions }: Props) => (
  <Field name={fieldName} required>
    {({ field: { name, value, onChange }, meta: { error } }) => (
      <FieldContainer>
        <Input error={error}
               name={name}
               formGroupClassName=""
               id={name}>
          <Select options={assetsOptions}
                  value={Array.isArray(value) ? value?.join(',') : value}
                  displayKey="key"
                  multi
                  inputId={name}
                  onChange={(newVal) => {
                    onChange({ target: { value: newVal?.split(','), name } });
                  }}
                  placeholder="Assets"
                  allowCreate={false} />
        </Input>
      </FieldContainer>
    )}
  </Field>
);

export default AssetsFilterFieldValue;
