import * as React from 'react';
import { useCallback } from 'react';

import HighlightingRules from 'views/components/sidebar/highlighting/HighlightingRules';
import type { Condition, Color, Value } from 'views/logic/views/formatting/highlighting/HighlightingRule';
import type HighlightingRule from 'views/logic/views/formatting/highlighting/HighlightingRule';

const DESCRIPTION = 'Field values can be highlighted. If a term or a value has more than one rule, the first matching rule is used.';

type Props = {
  setHighlightingRules: React.Dispatch<React.SetStateAction<Array<HighlightingRule>>>
}

const DataWarehouseHighlightingRules = ({ setHighlightingRules }: Props) => {
  const onUpdateRule = useCallback((targetRule: HighlightingRule, newField: string, newValue: Value, newCondition: Condition, newColor: Color) => {
    setHighlightingRules((cur) => {
      const newCur = [...cur];
      const index = newCur.findIndex((rule) => rule === targetRule);

      if (index === -1) {
        return newCur;
      }

      newCur[index] = targetRule.toBuilder().color(newColor).field(newField).value(newValue)
        .condition(newCondition)
        .build();

      return newCur;
    });

    return Promise.resolve();
  }, [setHighlightingRules]);

  const onCreateRule = useCallback((newRule: HighlightingRule) => {
    setHighlightingRules((cur) => ([...cur, newRule]));

    return Promise.resolve();
  }, [setHighlightingRules]);

  const onUpdateRules = useCallback((newRules: Array<HighlightingRule>) => {
    setHighlightingRules(newRules);

    return Promise.resolve();
  }, [setHighlightingRules]);

  const onDeleteRule = useCallback((rule: HighlightingRule) => {
    setHighlightingRules((cur) => cur.filter((r) => r !== rule));

    return Promise.resolve();
  }, [setHighlightingRules]);

  return (
    <HighlightingRules description={DESCRIPTION}
                       onDeleteRule={onDeleteRule}
                       onUpdateRules={onUpdateRules}
                       onUpdateRule={onUpdateRule}
                       onCreateRule={onCreateRule}
                       showSearchHighlightInfo={false} />
  );
};

export default DataWarehouseHighlightingRules;
