import __request__ from 'routing/request';
interface PaginatedResponse_ExternalAction {
    readonly [_key: string]: Object;
}
interface Options {
    readonly linkTemplate: string;
}
interface ExternalActionMap {
    readonly [_key: string]: ExternalAction;
}
interface ExternalActionRequest {
    readonly action_id: string;
    readonly fieldNames: string[];
    readonly options: Options;
    readonly type: string;
    readonly title: string;
}
interface ExternalAction {
    readonly action_id: string;
    readonly fieldNames: string[];
    readonly options: Options;
    readonly id: string;
    readonly type: string;
    readonly title: string;
}
/**
 * Create a new External Action
 * @param action action
 */
export function createExternalAction(action?: ExternalActionRequest): Promise<ExternalAction> {
    return __request__('POST', '/plugins/org.graylog.plugins.contentui/external_actions', action, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all External Actions
 */
export function getExternalActions(page: number = 1, page_size: number = 50): Promise<PaginatedResponse_ExternalAction> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/external_actions', null, { 'page': page, 'page_size': page_size }, {
        'Accept': ['application/json']
    });
}
/**
 * Get External Actions mappings as used by the UI
 */
export function getExternalActionsMapping(): Promise<ExternalActionMap> {
    return __request__('GET', '/plugins/org.graylog.plugins.contentui/external_actions/ui_mapping', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Update an Existing External Action
 * @param id id
 * @param action action
 */
export function updateExternalAction(id?: string, action?: ExternalActionRequest): Promise<ExternalAction> {
    return __request__('PUT', `/plugins/org.graylog.plugins.contentui/external_actions/${id}`, action, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete an existing External Action
 * @param id id
 */
export function deleteExternalAction(id?: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.contentui/external_actions/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get External Action by ID
 * @param id id
 */
export function getById(id?: string): Promise<ExternalAction> {
    return __request__('GET', `/plugins/org.graylog.plugins.contentui/external_actions/${id}`, null, {}, {
        'Accept': ['application/json']
    });
}
