import __request__ from 'routing/request';
interface RestartStatus {
    readonly install_in_progress: boolean;
}
/**
 * Reload and restart Illuminate processing on the current node.
 */
export function restartProcessing(): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/processing/restart', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the status of Illuminate restart.
 */
export function getInstallationStatus(): Promise<RestartStatus> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/processing/restart', null, {}, {
        'Accept': ['application/json']
    });
}
