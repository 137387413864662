import * as React from 'react';
import { useMemo } from 'react';
import { FieldArray, Field, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { useGetAssets } from 'security-app/hooks/useAssetsAPI';
import { Button, Input } from 'components/bootstrap';
import { IconButton, Spinner } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';
import type { FieldFilters } from 'data-warehouse/Types';
import useFieldTypes from 'data-warehouse/hooks/useFieldTypes';
import FieldSelect from 'data-warehouse/components/FieldSelect';

import DataWarehouseFilterValueField from './DataWarehouseFilterValueField';

const FieldContainer = styled.div`
  flex-basis: 100%;
`;
const RemoveContainer = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
`;

const List = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.xs};
  margin-bottom: ${theme.spacings.xs};
`);
const FilterGroup = styled.div(({ theme }) => css`
  flex-grow: 1;
  display: flex;
  gap: ${theme.spacings.xxs};
`);
const Item = styled.div(({ theme }) => css`
  display: flex;
  gap: ${theme.spacings.xxs};
`);

type Props = {
  maxFilters?: number
}

const FieldFiltersConfiguration = ({ maxFilters = undefined }: Props) => {
  const { values: { fieldFilters: filters }, setFieldValue } = useFormikContext<{ fieldFilters: Array<FieldFilters> }>();
  const { data: filterableFields, isInitialLoading: isLoadingFilterableField } = useFieldTypes();
  const { assets, loadingAssets: isLoadingAssets } = useGetAssets({ page: 1, perPage: 0 });
  const isEmpty = (filters ?? []).length === 0;
  const assetsOptions = useMemo(() => assets?.sort((s1, s2) => defaultCompare(s1.name, s2.name))
    .map(({ id, name }) => ({
      value: id,
      label: name,
    })), [assets]);

  return (
    <FieldArray name="fieldFilters"
                validateOnChange={false}
                render={({ remove, push }) => (
                  <>
                    {isLoadingFilterableField && <Spinner />}
                    {(!isEmpty && !isLoadingFilterableField && !isLoadingAssets) && (
                      <List>
                        {filters.map((filter, index) => (
                          /* eslint-disable-next-line react/no-array-index-key */
                          <Item key={`filter-field-${index}`}>
                            <FilterGroup>
                              <Field name={`fieldFilters.${index}.field_name`} required>
                                {({ field: { name, value }, meta: { error } }) => (
                                  <FieldContainer>
                                    <Input error={error}
                                           formGroupClassName=""
                                           id={`field_name-${index}`}>
                                      <FieldSelect value={value}
                                                   id={name}
                                                   name={name}
                                                   size="normal"
                                                   onChange={(newVal) => {
                                                     setFieldValue(`fieldFilters.${index}.field_name`, newVal);
                                                   }}
                                                   placeholder="Field name" />

                                    </Input>
                                  </FieldContainer>
                                )}
                              </Field>
                              <DataWarehouseFilterValueField fieldName={`fieldFilters.${index}.value`}
                                                             field={filterableFields.find((field) => field.name === filter.field_name)}
                                                             assetsOptions={assetsOptions} />
                              <RemoveContainer>
                                {(filters.length > 0) && <IconButton name="delete" onClick={() => (remove(index))} title="Remove field" />}
                              </RemoveContainer>
                            </FilterGroup>
                          </Item>
                        ))}
                      </List>
                    )}
                    <Button disabled={maxFilters && filters?.length >= maxFilters} bsSize="xs" onClick={() => push({ field_name: '', value: '' })} name="plus" title="Add field filter">Add field</Button>
                  </>
                )} />
  );
};

export default FieldFiltersConfiguration;
