import { useLayoutEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import type * as React from 'react';

import { VISUALIZATION_TABLE_HEADER_HEIGHT } from 'views/Constants';
import type { PageRefs, TableRef } from 'logview/components/LogViewWidget';
import type { LogViewState } from 'logview/components/contexts/LogViewStateProvider';
import type { InfiniteScrollDirection } from 'logview/types';
import { isInfiniteScrollUp, isInfiniteScrollDown } from 'logview/helpers';

import { LOADING_ROW_HEIGHT } from '../LogViewTable';

type Props = {
  tableRef: TableRef,
  pageRefs: PageRefs,
  lastPositionRef: React.MutableRefObject<number>,
  scrollPositionUpdate: LogViewState['scrollPositionUpdate'],
  infiniteScrollDirection: InfiniteScrollDirection,
  setFinishedScrollPositionUpdate: (finished: boolean) => void,
}

export default ({
  tableRef,
  pageRefs,
  lastPositionRef,
  scrollPositionUpdate,
  infiniteScrollDirection,
  setFinishedScrollPositionUpdate,
}: Props) => {
  const prevScrollPositionUpdate = useRef(scrollPositionUpdate);

  useLayoutEffect(() => {
    let scrollPositionAnimation;

    if (!isEqual(prevScrollPositionUpdate.current, scrollPositionUpdate)) {
      const { targetPage, direction, targetPageOffsetTop, loadedAllPrevMessages } = scrollPositionUpdate;

      const updateScrollPosition = () => {
        const targetPageRef = targetPage ? pageRefs.current[targetPage] : undefined;
        const loadingRowHeight = loadedAllPrevMessages ? 0 : LOADING_ROW_HEIGHT;

        if (targetPageRef) {
          const upTopOffset = lastPositionRef.current + targetPageRef.offsetTop;
          const downTopOffset = lastPositionRef.current - targetPageOffsetTop;

          if (isInfiniteScrollUp(infiniteScrollDirection)) {
            const staticElementsHeight = loadingRowHeight + VISUALIZATION_TABLE_HEADER_HEIGHT;

            if (direction === 'up') {
              // eslint-disable-next-line no-param-reassign
              tableRef.current.scrollTop = upTopOffset - staticElementsHeight;
            }

            if (direction === 'down') {
              // eslint-disable-next-line no-param-reassign
              tableRef.current.scrollTop = downTopOffset + staticElementsHeight;
            }
          }

          if (isInfiniteScrollDown(infiniteScrollDirection)) {
            if (direction === 'up') {
              // eslint-disable-next-line no-param-reassign
              tableRef.current.scrollTop = upTopOffset - VISUALIZATION_TABLE_HEADER_HEIGHT;
            }

            if (direction === 'down') {
              // eslint-disable-next-line no-param-reassign
              tableRef.current.scrollTop = downTopOffset + VISUALIZATION_TABLE_HEADER_HEIGHT;
            }
          }
        }

        prevScrollPositionUpdate.current = scrollPositionUpdate;
      };

      scrollPositionAnimation = requestAnimationFrame(updateScrollPosition);
      setFinishedScrollPositionUpdate(true);
    }

    return () => {
      if (scrollPositionAnimation) {
        cancelAnimationFrame(scrollPositionAnimation);
      }
    };
  }, [tableRef, pageRefs, prevScrollPositionUpdate, scrollPositionUpdate, lastPositionRef, setFinishedScrollPositionUpdate, infiniteScrollDirection]);
};
