import * as React from 'react';
import { useCallback, useImperativeHandle, useMemo, useState } from 'react';

import type { Event } from 'components/events/events/types';
import type { EventActionComponentProps } from 'views/types';
import MenuItem from 'components/bootstrap/menuitem/MenuItem';
import SetStatusBulk from 'security-app/components/SecurityEvents/Alerts/AlertsModals/SetStatusModal/SetStatusBulk';

interface Modal {
  toggle: () => void;
}

type Props = {
  events: Array<Event>,
}

export const BulkSetStatusModal: React.ComponentType<Props & { ref: React.LegacyRef<Modal> }> = React.forwardRef<Modal, Props>(({ events }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const eventIds = useMemo(() => events.map((event) => event.id), [events]);
  const onClose = useCallback(() => setShowModal(false), []);

  useImperativeHandle(ref, () => ({
    toggle: () => setShowModal(true),
  }));

  return (
    <SetStatusBulk eventIds={eventIds} onClose={onClose} show={showModal} />
  );
});

export const BulkSetStatusAction = ({ modalRef }: EventActionComponentProps<Modal>) => {
  const onClick = useCallback(() => modalRef().toggle(), [modalRef]);

  return <MenuItem onClick={onClick}>Set Status</MenuItem>;
};
