import * as React from 'react';
import * as Immutable from 'immutable';
import { useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import Routes from 'routing/Routes';
import { Alert, Col, Row, Input } from 'components/bootstrap';
import { Headline } from 'components/common/Section/SectionComponent';
import PaginatedItem from 'components/common/PaginatedItemOverview/PaginatedItem';
import RolesSelector from 'components/permissions/RolesSelector';
import type FetchError from 'logic/errors/FetchError';
import type UserOverview from 'logic/users/UserOverview';
import type Role from 'logic/roles/Role';
import { FormSubmit } from 'components/common';
import useHistory from 'routing/useHistory';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';

import LicenseCheck from '../../../license/LicenseCheck';
import NameFormGroup from '../form/NameFormGroup';
import DescriptionFormGroup from '../form/DescriptionFormGroup';
import Team from '../../logic/Team';
import TeamsDomain from '../../domainActions/TeamsDomain';
import UsersSelector from '../team-edit/UsersSelector';

const NoSelectedItem = styled.div`
  padding-top: 7px;
`;

const _onSubmit = ({ name, description }, users, roles, setSubmitError, history) => {
  setSubmitError(null);

  const newTeam = Team.builder()
    .name(name)
    .users(users)
    .roles(roles)
    .description(description)
    .build();

  return TeamsDomain.create(newTeam).then(() => {
    history.push(Routes.pluginRoute('SYSTEM_TEAMS'));
  },
  (error) => setSubmitError(error));
};

const TeamCreate = () => {
  const [submitError, setSubmitError] = useState<FetchError | undefined>();
  const [team, setTeam] = useState(Team.empty());
  const [selectedUsers, setSelectedUsers] = useState<Immutable.Set<UserOverview>>(Immutable.Set());
  const [selectedRoles, setSelectedRoles] = useState<Immutable.Set<Role>>(Immutable.Set());
  const history = useHistory();
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();

  const _handleCancel = () => history.push(Routes.getPluginRoute('SYSTEM_TEAMS'));

  const _onAssignUser = (users) => {
    setSelectedUsers(selectedUsers.union(users));

    return Promise.resolve(
      setTeam(team.toBuilder().users(team.users.union(users.map((u) => u.id))).build()),
    );
  };

  const _onAssignRole = (roles) => {
    setSelectedRoles(selectedRoles.union(roles));

    return Promise.resolve(
      setTeam(team.toBuilder().roles(team.roles.union(roles.map((r) => r.id))).build()),
    );
  };

  const _onUnassignUser = (user) => {
    setSelectedUsers(selectedUsers.remove(user));
    setTeam(team.toBuilder().users(team.users.remove(user.name)).build());
  };

  const _onUnassignRole = (role) => {
    setSelectedRoles(selectedRoles.remove(role));
    setTeam(team.toBuilder().roles(team.roles.remove(role.id)).build());
  };

  return (
    <LicenseCheck featureName="teams" displayWarningContainer>
      {({ licenseIsValid }) => {
        if (!licenseIsValid) {
          return null;
        }

        return (
          <Row className="content">
            <Col lg={8}>
              <Formik onSubmit={(data) => {
                sendTelemetry(TELEMETRY_EVENT_TYPE.TEAM.CREATED, {
                  app_pathname: getPathnameWithoutId(pathname),
                  app_section: 'teams-overview',
                  app_action_value: 'team-create',
                });

                _onSubmit(data, team.users, team.roles, setSubmitError, history);
              }}
                      initialValues={{ name: undefined, description: undefined }}>
                {({ isSubmitting, isValid }) => (
                  <Form className="form form-horizontal">
                    <div>
                      <Headline>Profile</Headline>
                      <NameFormGroup />
                      <DescriptionFormGroup />
                    </div>
                    <div>
                      <Headline>Users</Headline>
                      <Input id="users-selector-input"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Assign Users">
                        <UsersSelector onSubmit={_onAssignUser} team={team} />
                      </Input>

                      <Input id="selected-users-overview"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Selected Users">
                        <>
                          {selectedUsers.toArray().map((user) => (
                            <PaginatedItem item={user}
                                           onDeleteItem={(data) => _onUnassignUser(data)}
                                           key={user.name} />
                          ))}
                          {selectedUsers.size <= 0 && <NoSelectedItem>No selected users</NoSelectedItem>}
                        </>
                      </Input>
                    </div>
                    <div>
                      <Headline>Roles</Headline>
                      <Input id="roles-selector-input"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Assign Roles">
                        <RolesSelector onSubmit={_onAssignRole} assignedRolesIds={team.roles} submitOnSelect />
                      </Input>

                      <Input id="selected-users-overview"
                             labelClassName="col-sm-3"
                             wrapperClassName="col-sm-9"
                             label="Selected Roles">
                        <>
                          {selectedRoles.toArray().map((role) => (
                            <PaginatedItem item={role}
                                           onDeleteItem={(data) => _onUnassignRole(data)}
                                           key={role.name} />
                          ))}
                          {selectedRoles.size <= 0 && <NoSelectedItem>No selected roles</NoSelectedItem>}
                        </>
                      </Input>
                    </div>
                    {submitError && (
                      <Row>
                        <Col xs={9} xsOffset={3}>
                          <Alert bsStyle="danger" title="Failed to create team">
                            {submitError?.additional?.res?.text}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={9} mdOffset={3}>
                        <FormSubmit disabledSubmit={!isValid}
                                    submitButtonText="Create team"
                                    submitLoadingText="Creating team..."
                                    isSubmitting={isSubmitting}
                                    isAsyncSubmit
                                    onCancel={_handleCancel} />
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        );
      }}
    </LicenseCheck>
  );
};

export default TeamCreate;
