import * as React from 'react';
import { useState } from 'react';
import * as Immutable from 'immutable';

import ExportTimerangeContextProvider from 'logview/components/contexts/ExportTimerangeContextProvider';
import type { AbsoluteTimeRange } from 'views/logic/queries/Query';
import type { After, OnLoadMessages, LogViewMessage, InfiniteScrollDirection } from 'logview/types';

import ListStateProvider from './ListStateProvider';
import ScrollPositionProvider from './ScrollPositionProvider';

import type { TableRef, PageRefs } from '../LogViewWidget';

export type LogViewState = {
  status: 'initial' | 'reset' | 'update',
  listState: {
    after: After,
    loadedPages: { [pageIndex: string]: Array<LogViewMessage> },
    loadedMessagesCount: number,
    visiblePagesIds: Immutable.Set<number>,
  },
  scrollPositionUpdate: {
    direction: 'up' | 'down' | undefined,
    targetPage: number | undefined,
    targetPageOffsetTop: number | undefined,
    loadedAllPrevMessages: boolean,
  },
}

const _getInitialState = ({ after, messages, status }): LogViewState => ({
  status,
  listState: {
    after,
    loadedPages: { 1: messages },
    loadedMessagesCount: messages.length,
    visiblePagesIds: Immutable.Set([1]),
  },
  scrollPositionUpdate: {
    direction: undefined,
    targetPage: undefined,
    targetPageOffsetTop: undefined,
    loadedAllPrevMessages: false,
  },
});

type Props = {
  children: (payload: { resetListState: () => void }) => React.ReactNode,
  pageRefs: PageRefs,
  initialAfter: After,
  messages: Array<LogViewMessage>,
  tableRef: TableRef,
  effectiveTimerange: AbsoluteTimeRange,
  onLoadMessages: OnLoadMessages,
  total: number | undefined,
  infiniteScrollDirection: InfiniteScrollDirection
}

const LogViewStateProvider = ({ initialAfter, total, effectiveTimerange, messages, tableRef, pageRefs, children, onLoadMessages, infiniteScrollDirection }: Props) => {
  const [logViewState, setLogViewState] = useState(_getInitialState({
    after: initialAfter,
    messages,
    status: 'initial',
  }));

  // We need to change the type on every reset to ensure the component always rerenders.
  const resetListState = () => setLogViewState(_getInitialState({
    after: initialAfter,
    messages,
    status: logViewState.status === 'initial' ? 'reset' : 'initial',
  }));

  const updateLogViewState = (newLogViewState: Omit<LogViewState, 'status'>) => {
    setLogViewState({
      ...newLogViewState,
      status: 'update',
    });
  };

  return (
    <ScrollPositionProvider listStateStatus={logViewState.status}
                            pageRefs={pageRefs}
                            scrollPositionUpdate={logViewState.scrollPositionUpdate}
                            infiniteScrollDirection={infiniteScrollDirection}
                            tableRef={tableRef}>
      <ListStateProvider tableRef={tableRef}
                         pageRefs={pageRefs}
                         after={logViewState.listState.after}
                         total={total}
                         infiniteScrollDirection={infiniteScrollDirection}
                         onLoadMessages={onLoadMessages}
                         listState={logViewState.listState}
                         updateLogViewState={updateLogViewState}>
        <ExportTimerangeContextProvider pages={logViewState.listState.loadedPages} effectiveTimerange={effectiveTimerange}>
          {children({ resetListState })}
        </ExportTimerangeContextProvider>
      </ListStateProvider>
    </ScrollPositionProvider>
  );
};

export default LogViewStateProvider;
