import * as React from 'react';
import { useCallback } from 'react';

import { Button } from 'components/bootstrap';
import { Modal } from 'security-app/components/common/Modal';
import { useModalContext } from 'security-app/components/common/Modal/ModalContext';
import ReplaySearch from 'components/events/ReplaySearch';

function ReplayEvent() {
  const { entity: securityEvent, setEntity, setModal } = useModalContext();
  const alertId = securityEvent.event.id;
  const definitionId = securityEvent.event.event_definition_id;

  const onCancel = useCallback(() => {
    setModal(null);
    setEntity(null);
  }, [setEntity, setModal]);

  return (
    <Modal show
           maxWidth="1500px"
           title="Replay Event"
           onClose={onCancel}
           closeOnBackdrop
           buttons={<Button bsStyle="primary" onClick={onCancel}>Close</Button>}>
      <ReplaySearch alertId={alertId} definitionId={definitionId} forceSidebarPinned />
    </Modal>
  );
}

export default ReplayEvent;
