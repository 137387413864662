import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { ConfirmDialog } from 'security-app/components/common/Modal';

import List from './List';

type Props = {
  confirmDisabled: boolean,
  closeModal: () => void,
  isLoading?: boolean,
  onConfirm: (ownerId: string) => void,
  show?: boolean,
  owner?: string
}

const UsersModal = ({ closeModal, confirmDisabled, isLoading = false, onConfirm, owner = undefined, show = false }: Props) => {
  const [ownerId, setOwnerId] = React.useState<string>(owner);
  const _onConfirm = useCallback(() => onConfirm(ownerId), [onConfirm, ownerId]);

  useEffect(() => {
    setOwnerId(owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const assignText = ownerId ? 'Assign new owner' : 'Remove owner';

  return (
    <ConfirmDialog show={show}
                   onConfirm={_onConfirm}
                   confirmDisabled={confirmDisabled}
                   isLoading={isLoading}
                   confirmText={assignText}
                   onCancel={closeModal}
                   title="Select an Event Owner">
      <List ownerId={ownerId} setOwnerId={setOwnerId} />
    </ConfirmDialog>
  );
};

export default UsersModal;
