import * as React from 'react';
import styled from 'styled-components';
import Immutable from 'immutable';
import { useCallback, useContext, useMemo } from 'react';

import FieldSelect from 'data-warehouse/components/FieldSelect';
import StickyBottomActions from 'views/components/widgets/StickyBottomActions';
import SaveOrCancelButtons from 'views/components/widgets/SaveOrCancelButtons';
import DescriptionBox from 'views/components/aggregationbuilder/DescriptionBox';
import FieldsConfiguration from 'views/components/widgets/FieldsConfiguration';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import { Alert, Button } from 'components/bootstrap';
import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { WAHREHOUSE_PREVIEW_FORM_ID } from 'data-warehouse/search/SearchBar';
import { Icon } from 'components/common';
import type DataWarehouseWidgetConfig from 'data-warehouse/logic/DataWarehouseWIdgetConfig';

import DataWarehouseFieldsContext from './DataWarehouseFieldsContext';

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 15px;
  display: flex;
`;

const Col = styled.div`
  height: 100%;
`;

const ControlsCol = styled(Col)`
  min-width: 315px;
  max-width: 500px;
  flex: 1.2;
  padding-right: 5px;
  margin-right: 10px;
`;

const LogViewCol = styled(Col)`
  flex: 3;
  overflow-y: hidden;
`;

const LogViewEditFieldSelect = (props: React.ComponentProps<typeof FieldSelect>) => <FieldSelect {...props} allowCreate />;

type Props = React.PropsWithChildren<{
  config: DataWarehouseWidgetConfig,
  onCancel: () => void,
  onChange: (newConfig: DataWarehouseWidgetConfig) => void,
  isFetching: boolean,
}>

const DataWarehouseLogViewEdit = ({ children = undefined, config, onChange, onCancel, isFetching }: Props) => {
  const { all } = useContext(FieldTypesContext);
  const onChangeFields = useCallback((newFields: Array<string>) => {
    onChange(config.toBuilder().fields(Immutable.OrderedSet(newFields)).build());
  }, [config, onChange]);

  const hasCustomFields = useMemo(() => {
    const relevantFields = config.fields.subtract(['message', 'timestamp']);

    return relevantFields.filter((field) => !all.find((warehouseField) => warehouseField.name === field)).size >= 1;
  }, [all, config.fields]);

  return (
    <DataWarehouseFieldsContext.Provider value={Immutable.List<FieldTypeMapping>(config.fields)}>
      <Container>
        <ControlsCol>
          <StickyBottomActions actions={<SaveOrCancelButtons onCancel={onCancel} />}
                               alignActionsAtBottom>
            <DescriptionBox description="Field Selection and Order">
              <FieldsConfiguration onChange={onChangeFields}
                                   fieldSelect={LogViewEditFieldSelect}
                                   createSelectPlaceholder="Select or type in any field name"
                                   selectSize="normal"
                                   selectedFields={config.fields.toArray()}
                                   menuPortalTarget={document.body} />
            </DescriptionBox>
            {hasCustomFields && (
              <Alert bsStyle="warning">
                <p>
                  Newly added custom fields will appear in the log viewer with empty values. To populate these
                  values, you need to re-execute the Data Preview query.
                </p>
                <Button type="submit"
                        bsSize="xsmall"
                        bsStyle="success"
                        disabled={isFetching}
                        form={WAHREHOUSE_PREVIEW_FORM_ID}>
                  <Icon name="search" />Re-execute search
                </Button>
              </Alert>
            )}
            <Alert bsStyle="info">
              The field select contains options for fields which exist as columns in the warehouse.
              It is possible to type in and add any custom field name, by clicking on the <i>Create &quot;custom_field&quot;</i> option.<br />
            </Alert>
          </StickyBottomActions>
        </ControlsCol>
        <LogViewCol>
          {children}
        </LogViewCol>
      </Container>
    </DataWarehouseFieldsContext.Provider>
  );
};

export default DataWarehouseLogViewEdit;
