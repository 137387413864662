import * as React from 'react';

import PageNavigation from 'components/common/PageNavigation';
import DataWarehouseRoutes from 'data-warehouse/Routes';
import { Row } from 'components/bootstrap';
import { filterCloudMenuItems } from 'util/conditional/filterMenuItems';

const NAV_ITEMS = [
  { title: 'Overview', path: DataWarehouseRoutes.ARCHIVE.LIST, exactPathMatch: true },
  { title: 'Backend', path: DataWarehouseRoutes.BACKEND, exactPathMatch: true },
  { title: 'Configuration', path: DataWarehouseRoutes.CONFIGURATION, exactPathMatch: true },
];
const navItems = filterCloudMenuItems(NAV_ITEMS, [DataWarehouseRoutes.BACKEND]);

const DataWarehousePageNavigation = () => (
  <Row>
    <PageNavigation items={navItems} />
  </Row>
);

export default DataWarehousePageNavigation;
