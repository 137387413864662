import * as React from 'react';
import styled, { css } from 'styled-components';

import type { QueryValidationState, StreamDataRouting } from 'views/components/searchbar/queryvalidation/types';
import { Explanation } from 'views/components/searchbar/queryvalidation/QueryValidation';
import { Icon, IfPermitted } from 'components/common';
import { Button } from 'components/bootstrap';
import DataWarehouseRoutes from 'data-warehouse/Routes';

import DataWarehouseSearchRetreive from './DataWarehouseSearchRetreive';

const StyledIcon = styled(Icon)(({ theme }) => css`
  color: ${theme.colors.variant.info};
  margin-right: ${theme.spacings.xxs};
`);

const WarehousePreviewLinks = ({ streams }: { streams: Array<StreamDataRouting> }) => (
  <>
    {streams.map(({ stream_name: streamName, stream_id: streamId }, index) => (
      <span key={streamId}>
        {index !== 0 && <br />}
        <Button bsStyle="link" href={`${DataWarehouseRoutes.PREVIEW}?streams=${streamId}`}>From stream <i>{streamName}</i></Button>
      </span>
    ))}
  </>
);

type Props = {
  validationState: QueryValidationState;
};

const DataWarehouseQueryValidation = ({ validationState } : Props) => {
  const hasDataWarehouseData = validationState.status === 'INFO' && validationState.context?.data_routed_streams.length > 0;

  if (!hasDataWarehouseData) {
    return null;
  }

  const streamIds = validationState.context?.data_routed_streams.map((data) => data.stream_id);
  const timeRange = validationState.context?.searched_time_range;

  return (
    <Explanation>
      <span>
        <p>
          <StyledIcon name="database" /> Additional data that meets this search criteria may exist in the Data Warehouse.
        </p>
        <p>
          <b>Preview Logs</b>
          <br />
          <WarehousePreviewLinks streams={validationState.context?.data_routed_streams} />
        </p>
        <IfPermitted permissions={['data_warehouse_archive:restore']}>
          <p>
            <b>Retrieve Logs</b>
            <br />
            <DataWarehouseSearchRetreive streamIds={streamIds} timeRange={timeRange} />
          </p>
        </IfPermitted>
      </span>
    </Explanation>
  );
};

export default DataWarehouseQueryValidation;
