import * as React from 'react';
import styled, { css } from 'styled-components';
import { useContext } from 'react';

import { LOADING_ROW_HEIGHT } from 'logview/components/LogViewTable';
import { Spinner } from 'components/common';
import type { InfiniteScrollDirection } from 'logview/types';
import type LogViewWidgetConfig from 'logview/logic/LogViewWidgetConfig';
import WidgetContext from 'views/components/contexts/WidgetContext';
import { isInfiniteScrollUp } from 'logview/helpers';

const LoadingIndicator = styled.div(({ theme }) => css`
  min-height: ${LOADING_ROW_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: ${theme.fonts.family.body};
  font-size: ${theme.fonts.size.body};
`);

const _directionDescriptor = (infiniteScrollDirection: InfiniteScrollDirection, sort: LogViewWidgetConfig['sort']) => {
  if (isInfiniteScrollUp(infiniteScrollDirection)) {
    if (sort === 'DESC') {
      return 'previous';
    }

    return 'next';
  }

  if (sort === 'DESC') {
    return 'next';
  }

  return 'previous';
};

type Props = {
  infiniteScrollDirection: InfiniteScrollDirection,
}

const LoadMoreRow = ({ infiniteScrollDirection }: Props) => {
  const widget = useContext(WidgetContext);
  const directionDescriptor = _directionDescriptor(infiniteScrollDirection, widget.config.sort);

  return (
    <LoadingIndicator key="loading-test">
      <Spinner delay={0} text={`Loading ${directionDescriptor} messages...`} />
    </LoadingIndicator>
  );
};

export default LoadMoreRow;
