import isEqual from 'lodash/isEqual';
import type { MutableRefObject } from 'react';

import type CancellablePromise from 'logic/rest/CancellablePromise';
import UserNotification from 'util/UserNotification';
import type { After, OnLoadMessages, LogViewMessage, InfiniteScrollDirection } from 'logview/types';
import { isInfiniteScrollUp } from 'logview/helpers';

import type { LogViewState } from './LogViewStateProvider';

import type { PageRefs } from '../LogViewWidget';

const MAX_VISIBLE_PAGES = 2;

type UpdateListState = (
  listState: LogViewState['listState'],
  scrollPositionUpdate: LogViewState['scrollPositionUpdate']
) => void

const _onAppendLoadedMessages = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
  infiniteScrollDirection,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: UpdateListState,
  infiniteScrollDirection: InfiniteScrollDirection,
}) => (newAfter: After, newMessages: Array<LogViewMessage>) => {
  if (internalListState.after && !isEqual(internalListState.after, newAfter)) {
    const topVisiblePage = internalListState.visiblePagesIds.max();
    const bottomVisiblePage = internalListState.visiblePagesIds.min();
    const newPageIndex = topVisiblePage + 1;
    let newVisiblePagesIds = internalListState.visiblePagesIds.add(newPageIndex);

    if (newPageIndex - bottomVisiblePage >= MAX_VISIBLE_PAGES) {
      newVisiblePagesIds = newVisiblePagesIds.delete(bottomVisiblePage);
    }

    // It is necessary to define targetPageOffsetTop before we update the list state
    const scrollPositionUpdate = {
      direction: isInfiniteScrollUp(infiniteScrollDirection) ? 'up' as const : 'down' as const,
      targetPage: newPageIndex - 1,
      targetPageOffsetTop: pageRefs.current[newPageIndex - 1].offsetTop,
      loadedAllPrevMessages,
    };

    updateListState(
      {
        ...internalListState,
        after: newAfter,
        loadedPages: { ...internalListState.loadedPages, [newPageIndex]: newMessages },
        loadedMessagesCount: internalListState.loadedMessagesCount + newMessages.length,
        visiblePagesIds: newVisiblePagesIds,
      },
      scrollPositionUpdate,
    );
  }
};

interface LogsResult {
  type: 'logs',
  after: After,
  messages: Array<any>,
  total: number,
}

declare module 'views/types' {
  interface SearchTypeResultTypes {
    logs: LogsResult,
  }
}

const _requestPrevPage = ({
  onLoadMessages,
  appendLoadedMessages,
  internalListState,
  loadPrevPromiseRef,
}: {
  onLoadMessages: OnLoadMessages,
  appendLoadedMessages: (newAfter: After, newMessages: Array<LogViewMessage>) => void,
  internalListState: LogViewState['listState'],
  loadPrevPromiseRef: MutableRefObject<Promise<void>>,
}) => {
  if (!loadPrevPromiseRef.current) {
    if (internalListState.after) {
      // eslint-disable-next-line no-param-reassign
      loadPrevPromiseRef.current = onLoadMessages(internalListState.after).then(({ after, messages }) => {
        appendLoadedMessages(after, messages);
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
      }).catch((error) => {
        // eslint-disable-next-line no-param-reassign
        loadPrevPromiseRef.current = undefined;
        UserNotification.error(`Fetching previous log messages failed with status: ${error}`);
      });
    } else {
      throw Error('Can not reexecute search, because parameter "after" is missing.');
    }
  }
};

const _prevPageIsInState = (listState: LogViewState['listState']) => {
  const topVisiblePage = listState.visiblePagesIds.max();
  const newPageIndex = topVisiblePage + 1;

  return !!listState.loadedPages[newPageIndex];
};

const _loadPrevPageFromState = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
  infiniteScrollDirection,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: UpdateListState,
  infiniteScrollDirection: InfiniteScrollDirection
}) => {
  const topVisiblePage = internalListState.visiblePagesIds.max();
  const bottomVisiblePage = internalListState.visiblePagesIds.min();
  const newTopPageIndex = topVisiblePage + 1;
  let newVisiblePagesIds = internalListState.visiblePagesIds.add(newTopPageIndex);

  if (newTopPageIndex - bottomVisiblePage >= MAX_VISIBLE_PAGES) {
    newVisiblePagesIds = newVisiblePagesIds.delete(bottomVisiblePage);
  }

  // It is necessary to define targetPageOffsetTop before we update the list state
  const scrollPositionUpdate = {
    direction: isInfiniteScrollUp(infiniteScrollDirection) ? 'up' as const : 'down' as const,
    targetPage: newTopPageIndex - 1,
    targetPageOffsetTop: pageRefs.current[newTopPageIndex - 1].offsetTop,
    loadedAllPrevMessages,
  };

  updateListState(
    {
      ...internalListState,
      visiblePagesIds: newVisiblePagesIds,
    },
    scrollPositionUpdate,
  );
};

const _loadNextPageFromState = ({
  internalListState,
  loadedAllPrevMessages,
  pageRefs,
  updateListState,
  infiniteScrollDirection,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: UpdateListState,
  infiniteScrollDirection: InfiniteScrollDirection
}) => {
  const topVisiblePage = internalListState.visiblePagesIds.max();
  const bottomVisiblePage = internalListState.visiblePagesIds.min();

  if (bottomVisiblePage <= 1) {
    return;
  }

  const newBottomPageIndex = bottomVisiblePage - 1;
  let newVisiblePagesIds = internalListState.visiblePagesIds.add(newBottomPageIndex);

  if ((topVisiblePage - newBottomPageIndex) >= MAX_VISIBLE_PAGES) {
    newVisiblePagesIds = newVisiblePagesIds.delete(topVisiblePage);
  }

  // It is necessary to define targetPageOffsetTop before we update the list state
  const scrollPositionUpdate = {
    direction: isInfiniteScrollUp(infiniteScrollDirection) ? 'down' as const : 'up' as const,
    targetPage: bottomVisiblePage,
    targetPageOffsetTop: pageRefs.current[bottomVisiblePage].offsetTop,
    loadedAllPrevMessages,
  };

  updateListState(
    {
      ...internalListState,
      visiblePagesIds: newVisiblePagesIds,
    },
    scrollPositionUpdate,
  );
};

export const loadPrevPage = ({
  finishedScrollPositionUpdateRef,
  internalListState,
  loadedAllPrevMessages,
  loadPrevPromiseRef,
  pageRefs,
  updateListState,
  onLoadMessages,
  infiniteScrollDirection,
}: {
  finishedScrollPositionUpdateRef: MutableRefObject<boolean>,
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  loadPrevPromiseRef: MutableRefObject<CancellablePromise<void>>,
  pageRefs: PageRefs,
  updateListState: UpdateListState,
  onLoadMessages: OnLoadMessages,
  infiniteScrollDirection: InfiniteScrollDirection,
}) => {
  if (finishedScrollPositionUpdateRef.current && !loadPrevPromiseRef.current) {
    const prevPageIsInState = _prevPageIsInState(internalListState);

    if (prevPageIsInState) {
      _loadPrevPageFromState({
        internalListState,
        pageRefs,
        updateListState,
        loadedAllPrevMessages,
        infiniteScrollDirection,
      });
    }

    if (!prevPageIsInState && !loadedAllPrevMessages) {
      const _appendLoadedMessages = _onAppendLoadedMessages({
        loadedAllPrevMessages,
        internalListState,
        pageRefs,
        updateListState,
        infiniteScrollDirection,
      });

      _requestPrevPage({
        onLoadMessages,
        appendLoadedMessages: _appendLoadedMessages,
        internalListState,
        loadPrevPromiseRef,
      });
    }
  }
};

export const loadNextPage = ({
  internalListState,
  pageRefs,
  updateListState,
  finishedScrollPositionUpdateRef,
  loadedAllPrevMessages,
  infiniteScrollDirection,
}: {
  internalListState: LogViewState['listState'],
  loadedAllPrevMessages: boolean,
  pageRefs: PageRefs,
  updateListState: UpdateListState,
  finishedScrollPositionUpdateRef: MutableRefObject<boolean>,
  infiniteScrollDirection: InfiniteScrollDirection
}) => {
  if (finishedScrollPositionUpdateRef.current) {
    _loadNextPageFromState({
      loadedAllPrevMessages,
      internalListState,
      pageRefs,
      updateListState,
      infiniteScrollDirection,
    });
  }
};
