import type { SalesforceGenericInputCreateRequest, SalesforceInputCreateRequest, FormDataType } from '../types';

export const toSalesforceInputCreateRequest = ({
  salesforceName,
  salesforceThrottleEnabled,
  pollingInterval,
  salesforceClientId,
  salesforceClientSecret,
  logTypes,
  salesforceBaseUrl,
  accessTokenExpiresIn,
}: FormDataType): SalesforceInputCreateRequest => ({
  name: salesforceName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  enable_throttling: !!salesforceThrottleEnabled?.value,
  salesforce_client_id: salesforceClientId.value,
  salesforce_client_secret: salesforceClientSecret.value,
  log_types: logTypes.value.split(','),
  salesforce_base_url: salesforceBaseUrl.value,
  access_token_expires_in: accessTokenExpiresIn.value,
  access_token_expiry_time_unit: 'HOURS',
});

export const toGenericInputCreateRequest = ({
  salesforceName,
  salesforceThrottleEnabled,
  salesforceClientId,
  salesforceClientSecret,
  pollingInterval,
  logTypes,
  salesforceBaseUrl,
  accessTokenExpiresIn,
}: FormDataType): SalesforceGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.salesforce.SalesforceInput',
  title: salesforceName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    throttling_allowed: !!salesforceThrottleEnabled?.value,
    salesforce_client_id: salesforceClientId.value,
    salesforce_client_secret: salesforceClientSecret.value,
    log_types: logTypes.value.split(','),
    salesforce_base_url: salesforceBaseUrl.value,
    access_token_expires_in: accessTokenExpiresIn.value,
    access_token_expiry_time_unit: 'HOURS',
  },
});
