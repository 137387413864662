import * as React from 'react';

import HelpBlock from 'components/bootstrap/HelpBlock';
import MDEditorFullView from 'security-app/components/common/MDEditor/MDEditorFullView';

type Props = {
  closeModal: () => void,
  onConfirm: (notes: string) => void,
  show?: boolean,
}

const helpBlock = (
  <HelpBlock>
    You can add additional notes here, which will be appended to the notes of all events you have selected.
  </HelpBlock>
);

const AddNotesModal = ({ closeModal, onConfirm, show = false }: Props) => {
  const [notes, setNotes] = React.useState<string>('');

  return show ? (
    <MDEditorFullView value={notes} show={show} onChange={setNotes} onClose={closeModal} onDone={onConfirm} helpBlock={helpBlock} />
  ) : null;
};

export default AddNotesModal;
