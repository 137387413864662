import * as React from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field } from 'formik';

import { Input, Button } from 'components/bootstrap';
import { Icon } from 'components/common';
import { TELEMETRY_EVENT_TYPE } from 'telemetry/Constants';
import { getPathnameWithoutId } from 'util/URLUtils';
import useSendTelemetry from 'logic/telemetry/useSendTelemetry';
import useLocation from 'routing/useLocation';
import UserNotification from 'util/UserNotification';

const SurveyWrapper = styled.div(({ theme }) => css`
  border: 1px solid ${theme.colors.cards.border};
  border-radius: ${theme.spacings.sm};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacings.sm};
  padding: ${theme.spacings.sm} ${theme.spacings.md};
`);

const RatingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const RatingText = styled.p(({ theme }) => css`
  color: ${theme.colors.text.secondary};
  margin-bottom: 0;
`);

const Ratings = styled.div(({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacings.sm};

  & .form-group {
    margin-bottom: 0!important;
  }
`);

const StyledRadioInput = styled(Input)`
  margin-top: 0;
  margin-bottom: 0;

  & label {
    padding-left: 0; 
  }

  & input {
    visibility: hidden;
  }
`;

const StyledIcon = styled(Icon)<{ $checked: boolean, $disabled: boolean }>(({ theme, $checked, $disabled }) => css`
  color: ${$checked ? theme.colors.text.secondary : undefined};
  cursor:  ${$disabled ? 'default' : 'pointer'};
  margin-bottom: 0;
`);

const ratings: Array<{
  title: string,
  icon: 'sentiment_very_satisfied' | 'sentiment_satisfied' | 'sentiment_dissatisfied',
  value: number
}> = [
  { title: 'Excellent!', icon: 'sentiment_very_satisfied', value: 3 },
  { title: 'OK', icon: 'sentiment_satisfied', value: 2 },
  { title: 'Not good', icon: 'sentiment_dissatisfied', value: 1 },
];

const SatisfactionSurvey = () => {
  const { pathname } = useLocation();
  const sendTelemetry = useSendTelemetry();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit = (values: { rating: number, review: string}) => {
    sendTelemetry(TELEMETRY_EVENT_TYPE.SECURITY_APP.INVESTIGATION_REPORT_BY_AI.USER_SURVEY, {
      app_pathname: getPathnameWithoutId(pathname),
      app_value: values,
    });

    setIsSubmitted(true);
    UserNotification.success('Thank you for your feedback!');
  };

  return isSubmitted ? null : (
    <Formik onSubmit={onSubmit} initialValues={{ rating: undefined, review: undefined }}>
      {({ values }) => (
        <Form className="form">
          <SurveyWrapper>
            <RatingWrapper>
              <RatingText>
                How well did we do?
              </RatingText>
              <Ratings>
                <Field name="rating">
                  {({ field: { name, value, onChange } }) => (
                    <>
                      {
                          ratings.map(({ value: itemValue, icon, title }) => {
                            const checked = value === itemValue;

                            return (
                              <StyledRadioInput key={itemValue}
                                                id={`${name}-${itemValue}`}
                                                onChange={() => onChange({ target: { value: itemValue, name } })}
                                                checked={checked}
                                                value={itemValue}
                                                name={name}
                                                disabled={isSubmitted}
                                                type="radio"
                                                label={(
                                                  <StyledIcon $checked={checked}
                                                              $disabled={isSubmitted}
                                                              size="sm"
                                                              name={icon}
                                                              title={title}
                                                              data-testid={`radio-option-${title}`}
                                                              type={checked ? 'solid' : 'regular'} />
                                                )} />
                            );
                          })
                        }
                    </>
                  )}
                </Field>
                {!isSubmitted && values.rating && (<Button bsSize="xs" type="submit">Submit</Button>)}
              </Ratings>
            </RatingWrapper>
            {(values.rating === 1 && !isSubmitted) ? (
              <Field name="review">
                {({ field: { name, value, onChange } }) => (
                  <Input id={name}
                         name={name}
                         type="textarea"
                         value={value}
                         onChange={onChange}
                         label={(
                           <span>What can be improved? <small className="text-muted">(Optional)</small></span>)}
                         rows={4} />
                )}
              </Field>
            ) : null}
          </SurveyWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default SatisfactionSurvey;
