import type { Sort, Attribute } from 'stores/PaginationTypes';
import {
  commonEventAttributes,
  eventsTableSpecificAttributes,
} from 'components/events/Constants';
import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import OwnerFilter from 'security-app/components/SecurityEvents/Alerts/OwnerFilter';

export const SECURITY_EVENTS_ENTITY_TABLE_ID = 'security_events';

export const securityEventDetailsAttributes: Array<Attribute> = [
  ...commonEventAttributes,
  {
    id: 'status',
    title: 'Status',
    type: 'STRING',
    filterable: true,
    filter_options: Object.entries(SECURITY_EVENT_STATUSES).map(([value, title]) => ({ value, title })),
  },
  {
    id: 'scores',
    title: 'Risk Score',
    type: 'STRING',
    sortable: true,
  },
  {
    id: 'event_id',
    title: 'ID',
    type: 'STRING',
    sortable: true,
  },
  {
    id: 'associated_investigations',
    title: 'Associated Investigations',
    type: 'OBJECT_ID',
  },
];
export const attributes: Array<Attribute> = [
  ...securityEventDetailsAttributes,
  ...eventsTableSpecificAttributes,
  {
    id: 'owner',
    title: 'Owner',
    type: 'STRING',
    filterable: true,
    filter_component: OwnerFilter,
  },
];

export const securityEventsTableElements = {
  defaultLayout: {
    entityTableId: SECURITY_EVENTS_ENTITY_TABLE_ID,
    defaultPageSize: 20,
    defaultSort: { attributeId: 'timestamp', direction: 'desc' } as Sort,
    defaultDisplayedAttributes: [
      'message',
      'scores',
      'owner',
      'alert',
      'status',
      'timestamp',
    ],
  },
  columnOrder: [
    'message',
    'scores',
    'owner',
    'alert',
    'status',
    'priority',
    'timestamp',
    'key',
    'event_definition_id',
    'event_definition_type',
    'group_by_fields',
    'remediation_steps',
    'event_id',
    'associated_investigations',
  ],
};
