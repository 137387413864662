import * as React from 'react';
import { useCallback, useImperativeHandle, useMemo, useState } from 'react';

import type { Event } from 'components/events/events/types';
import SendNotificationBulk from 'security-app/components/SecurityEvents/Alerts/AlertsModals/NotificationsModal/SendNotificationBulk';
import type { EventActionComponentProps } from 'views/types';
import MenuItem from 'components/bootstrap/menuitem/MenuItem';

interface Modal {
  toggle: () => void;
}

type Props = {
  events: Array<Event>,
  fromBulk?: boolean,
}

export const SendNotificationsModal: React.ComponentType<Props & { ref: React.LegacyRef<Modal> }> = React.forwardRef<Modal, Props>(({ events, fromBulk = false }, ref) => {
  const [showModal, setShowModal] = useState(false);
  const eventIds = useMemo(() => events.map((event) => event.id), [events]);
  const onClose = useCallback(() => setShowModal(false), []);

  useImperativeHandle(ref, () => ({
    toggle: () => setShowModal(true),
  }));

  return (
    <SendNotificationBulk eventIds={eventIds} onClose={onClose} show={showModal} fromBulk={fromBulk} />
  );
});

export const SendNotificationsEventAction = ({ modalRef }: EventActionComponentProps<Modal>) => {
  const onClick = useCallback(() => modalRef().toggle(), [modalRef]);

  return <MenuItem onClick={onClick}>Send Notifications</MenuItem>;
};
