import { useReducer } from 'react';

import type FetchError from 'logic/errors/FetchError';
import type { LogViewMessage } from 'logview/types';

export type SearchReducerAction =
  | { type: 'SET_ERROR'; payload: FetchError | null }
  | { type: 'SET_IS_FETCHING' }
  | { type: 'SET_MESSAGES'; payload: Array<LogViewMessage> | null }
  | { type: 'RESET_SEARCH_STATE' };

type State = {
  error: FetchError | null;
  hasBeenSubmitted: boolean;
  isFetching: boolean;
  messages: Array<LogViewMessage> | null;
};

const initialState: State = {
  error: null,
  hasBeenSubmitted: false,
  isFetching: false,
  messages: null,
};

const reducer = (state: State, action: SearchReducerAction): State => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.payload, isFetching: false, messages: null };
    case 'SET_IS_FETCHING':
      return { ...state, error: null, hasBeenSubmitted: true, isFetching: true, messages: null };
    case 'SET_MESSAGES':
      return { ...state, error: null, isFetching: false, messages: action.payload };
    case 'RESET_SEARCH_STATE':
      return { ...state, error: null, hasBeenSubmitted: false, isFetching: false, messages: null };
    default:
      return state;
  }
};

const useSearchReducer = () => useReducer(reducer, initialState);

export default useSearchReducer;
