import * as React from 'react';
import { useCallback } from 'react';

import { EmptyList, TD, RowTitle } from 'security-app/components/common';
import { GLCheckbox } from 'common/components';
import type { OwnerOptionType } from 'security-app/hooks/api/securityEventsAPI.types';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';

type Props = {
  ownerOptions: OwnerOptionType[],
  filters: ColumnFilterData,
  ownerId: string,
  setOwnerId: (owner: string) => void,
};

function ListRows({ ownerOptions, filters, ownerId, setOwnerId }: Props) {
  const selectOwner = useCallback((newOwner: OwnerOptionType) => () => {
    const newOwnerId = newOwner.id;

    if (newOwnerId !== ownerId) {
      setOwnerId(newOwner.id);
    } else {
      setOwnerId(undefined);
    }
  }, [ownerId, setOwnerId]);

  const strFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.join(',')]),
  );

  return ownerOptions.length < 1 ? (
    <EmptyList filters={strFilters}
               noSearchResultText="No User or Teams found for the provided criteria"
               noEntitiesExist="Currently there are no User or Teams to list"
               colSpan={3} />
  ) : (
    <>
      {ownerOptions.map((owner: OwnerOptionType) => (
        <tr key={owner.id}>
          <TD><GLCheckbox checked={owner.id === ownerId} onChange={selectOwner(owner)} /></TD>
          <TD>
            <RowTitle onClick={selectOwner(owner)}>
              {owner.name}
            </RowTitle>
          </TD>
          <TD>{owner.type}</TD>
        </tr>
      ))}
    </>
  );
}

export default ListRows;
