import * as React from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import { useContext } from 'react';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';
import { defaultCompare } from 'logic/DefaultCompare';
import StreamsContext from 'contexts/StreamsContext';

type Props = {
  fieldName: string,
}

const FieldContainer = styled.div`
  flex-basis: 100%;
`;

const StreamFilterFieldValue = ({ fieldName }: Props) => {
  const streams = useContext(StreamsContext);

  const streamOptions = streams.map((stream) => ({
    key: stream.title,
    value: stream.id,
  })).sort(({ key: key1 }, { key: key2 }) => defaultCompare(key1, key2));

  return (
    <Field name={fieldName} required>
      {({ field: { name, value, onChange }, meta: { error } }) => (
        <FieldContainer>
          <Input error={error}
                 name={name}
                 formGroupClassName=""
                 id={name}>
            <Select options={streamOptions}
                    value={Array.isArray(value) ? value?.join(',') : value}
                    displayKey="key"
                    multi
                    inputId={name}
                    onChange={(newVal) => {
                      onChange({ target: { value: newVal?.split(','), name } });
                    }}
                    placeholder="Streams"
                    allowCreate={false} />
          </Input>
        </FieldContainer>
      )}
    </Field>
  );
};

export default StreamFilterFieldValue;
