import * as React from 'react';
import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { OrderedSet } from 'immutable';
import styled, { css } from 'styled-components';

import FieldTypesContext from 'views/components/contexts/FieldTypesContext';
import { defaultCompare } from 'logic/DefaultCompare';
import Spinner from 'components/common/Spinner';
import Icon from 'components/common/Icon';
import IconButton from 'components/common/IconButton';
import Button from 'components/bootstrap/Button';
import type { Message } from 'views/components/messagelist/Types';
import { ClipboardIconButton } from 'components/common';
import type FieldType from 'views/logic/fieldtypes/FieldType';

import MessageDetailsContext from './contexts/MessageDetailsContext';
import LogViewMessageDetailsRow, { LogViewMessageDetailsHeadRow } from './LogViewMessageDetailsRow';

import LogViewWidgetConfig from '../logic/LogViewWidgetConfig';

const Container = styled.div(({ theme }) => css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  min-width: 450px;
  background: ${theme.colors.global.contentBackground};
  height: 100%;
  padding: 15px 15px 10px 15px;
  border: 1px solid ${theme.colors.gray[80]};
  z-index: 2;
  display: flex;
  flex-direction: column;
`);

const Table = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  font-size: ${theme.fonts.size.small};
  margin-bottom: 5px;
`);

const TableHead = styled.div(({ theme }) => css`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.gray[90]};
`);

const Header = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FooterElement = styled.div`
  margin: 3px;
`;

const HeaderIcon = styled.div`
  padding: 2px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

const HeaderId = styled.h4(({ theme }) => css`
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${theme.fonts.family.monospace};
  overflow: hidden;
  margin-right: 5px;
`);

type Props = {
  editing: boolean,
  onChangeConfig: (widgetConfig: LogViewWidgetConfig) => Promise<void>,
  widgetConfig: LogViewWidgetConfig,
  onLoadMessage: (message: unknown) => Promise<Message>,
  renderFieldName?: (field: string, fieldType: FieldType) => React.ReactElement,
  renderFieldValue?: (field: string, fieldType: FieldType, value: string) => React.ReactElement,
}

const LogViewMessageDetails = ({ onLoadMessage, widgetConfig, editing, onChangeConfig, renderFieldValue = undefined, renderFieldName = undefined }: Props) => {
  const [message, setMessage] = useState<Message | undefined | null>();
  const [currentConfig, setCurrentConfig] = useState(widgetConfig);
  const {
    activeMessageDetails,
    selectNextMessage,
    selectPrevMessage,
    setActiveMessageDetailsId,
  } = useContext(MessageDetailsContext);
  const { all } = useContext(FieldTypesContext);
  const columns = currentConfig.fields;
  const onClose = useCallback(() => setActiveMessageDetailsId(undefined), [setActiveMessageDetailsId]);

  useEffect(() => {
    if (activeMessageDetails) {
      onLoadMessage(activeMessageDetails).then((response: Message) => {
        setMessage(response);
      });
    }
  }, [activeMessageDetails, onLoadMessage]);

  const closeDetails = useCallback(() => {
    if (!currentConfig.fields.equals(widgetConfig.fields)) {
      onChangeConfig(currentConfig);
    }

    onChangeConfig(currentConfig);
    onClose();
  }, [currentConfig, widgetConfig.fields, onChangeConfig, onClose]);

  const dirty = useCallback(() => !currentConfig.fields.equals(OrderedSet(widgetConfig.fields)),
    [currentConfig.fields, widgetConfig.fields]);

  const onSelect = useCallback((field) => {
    const newFields = columns.includes(field) ? columns.remove(field) : columns.add(field);
    const newConfig = currentConfig.toBuilder().fields(newFields).build();
    setCurrentConfig(newConfig);
  }, [columns, currentConfig]);

  const allSelected = useCallback(() => {
    const fields = Object.keys(message?.formatted_fields);

    return currentConfig.fields.sort().equals(OrderedSet(fields).sort());
  }, [currentConfig.fields, message?.formatted_fields]);

  const onSelectAll = useCallback(() => {
    const fields = Object.keys(message?.formatted_fields);

    if (allSelected()) {
      const newConfig = currentConfig.toBuilder().fields(LogViewWidgetConfig.defaultFields).build();
      setCurrentConfig(newConfig);

      return;
    }

    const allNewConfig = currentConfig.toBuilder().fields(OrderedSet(fields)).build();
    setCurrentConfig(allNewConfig);
  }, [allSelected, currentConfig, message?.formatted_fields]);

  const findFieldType = useCallback((field) => all.find((f) => f.name === field), [all]);

  const sortedRows = useMemo(() => {
    if (!message?.formatted_fields) {
      return [];
    }

    return Object.entries(message?.formatted_fields).sort(([key1], [key2]) => defaultCompare(key1, key2));
  }, [message?.formatted_fields]);

  if (!activeMessageDetails) {
    return null;
  }

  if (!message) {
    return <Spinner delay={300} />;
  }

  return (
    <Container data-testid={`log-view-message-details-${activeMessageDetails.message._id}`}>
      <Header>
        <HeaderTitle>
          <HeaderIcon><Icon name="mail" /></HeaderIcon>
          <HeaderId title={activeMessageDetails.message._id}>{activeMessageDetails.message._id}</HeaderId>
        </HeaderTitle>
        <div>
          <ClipboardIconButton buttonTitle="Copy message"
                               text={JSON.stringify(message.fields, null, 2)} />
          <IconButton disabled={!selectPrevMessage} title="Previous" name="arrow_upward" onClick={selectPrevMessage} />
          <IconButton disabled={!selectNextMessage} title="Next" name="arrow_downward" onClick={selectNextMessage} />
          <IconButton title="Close" name="close" onClick={onClose} />
        </div>
      </Header>
      <Table>
        <TableHead>
          <LogViewMessageDetailsHeadRow onSelectAll={onSelectAll} allSelected={allSelected} hideCheckbox={editing} />
        </TableHead>
        {sortedRows.map(([field, value]) => (
          <LogViewMessageDetailsRow key={`${field}-${value}`}
                                    field={field}
                                    value={value}
                                    type={findFieldType(field)}
                                    onSelect={() => onSelect(field)}
                                    selected={columns.includes(field)}
                                    renderFieldName={renderFieldName}
                                    renderFieldValue={renderFieldValue}
                                    hideCheckbox={editing} />
        ))}
      </Table>
      {dirty() && (
        <Footer>
          <FooterElement>
            <Button title="Save & Close" bsSize="small" bsStyle="primary" onClick={closeDetails}>Save & Close</Button>
          </FooterElement>
        </Footer>
      )}
    </Container>
  );
};

export default LogViewMessageDetails;
