import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import UserNotification from 'util/UserNotification';
import { useInvestigationDrawer } from 'security-app/components/Investigations/contexts/InvestigationDrawer';
import { useDashboardContext } from 'security-app/components/Investigations/Dashboard';
import type { ColumnFilterData } from 'security-app/components/common/Filters/ColumnFilter.types';
import type { EnableNotificationsResponse } from 'security-app/hooks/InvestigationsAPI.types';
import { defaultOnError } from 'util/conditional/onError';

import {
  fetchInvestigations,
  getInvestigationDetails,
  newInvestigation,
  updateInvestigations,
  archiveInvestigation,
  fetchPriorities,
  fetchStatuses,
  fetchUsers,
  fetchTeams,
  addNote,
  deleteNote,
  editNote,
  addEvidence,
  bulkAddEvidence,
  addPriority,
  updatePriority,
  reprioritizePriorities,
  deletePriority,
  addStatus,
  updateStatus,
  deleteStatus,
  removeEvidence,
  bulkArchiveInvestigation,
  deleteInvestigation,
  bulkDeleteInvestigations,
  setDefaultPriority,
  removeDefaultPriority,
  setDefaultStatus,
  removeDefaultStatus,
  enableNotifications,
  fetchInvestigationsConfig,
} from './api/investigationsAPI';
import type {
  InvestigationsDetailsAPIType,
  InvestigationsIndexAPIType,
  PriorityAPIType,
  StatusAPIType,
  UserAPIType,
  TeamAPIType,
  UpdateInvestigationResponseAPIType,
  ConfigApiType,
} from './api/investigationsAPI.types';

export type PaginatedProps = {
  page: number,
  perPage: number,
  query?: string,
  orderBy?: string,
  direction?: 'asc' | 'desc',
  filters?: ColumnFilterData,
};

export function useGetInvestigations({
  page,
  perPage,
  query,
  orderBy,
  direction,
  filters,
}: PaginatedProps, archived?: boolean, userTimezone?: string) {
  const { data, isLoading } = useQuery<InvestigationsIndexAPIType, Error>(
    ['get-investigations', page, perPage, query, orderBy, direction, filters, userTimezone, archived],
    () => defaultOnError(fetchInvestigations(page, perPage, query, orderBy, direction, filters, archived, userTimezone), 'Error fetching investigations'),
    {
      retry: 2,
      keepPreviousData: true,
    },
  );

  return {
    loadingInvestigations: isLoading,
    investigations: isLoading ? [] : data?.investigations,
    pagination: {
      page: data?.page || page,
      perPage: data?.per_page || perPage,
      total: data?.total || 0,
      grandTotal: data?.grand_total || 0,
      count: data?.count || 0,
    },
  };
}

export function useGetInvestigationDetails(invId: string) {
  const { selectedInvestigationId, setSelectedInvestigationId } = useInvestigationDrawer();
  const { data, isLoading, isError } = useQuery<InvestigationsDetailsAPIType, Error>(
    ['get-investigation-details', invId],
    () => {
      try {
        return getInvestigationDetails(invId);
      } catch (error) {
        if (selectedInvestigationId === invId) {
          setSelectedInvestigationId(undefined);
          UserNotification.warning('The investigation you were viewing has been deleted');
        } else if (error.status === 404) {
          UserNotification.error(`Unable to find Investigation with id <${invId}>, please ensure it wasn't deleted.`,
            'Could not retrieve Investigation');
        } else {
          UserNotification.error(error.message);
        }

        throw error;
      }
    },
    {
      retry: 2,
      enabled: !!invId,
    },
  );

  return {
    loadingInvestigationDetails: isLoading,
    fetchError: isError,
    currentInvestigation: data,
  };
}

export function useNewInvestigation() {
  const queryClient = useQueryClient();
  const { forceRefreshDashboard } = useDashboardContext();

  const { mutateAsync, isLoading } = useMutation(
    newInvestigation,
    {
      onSuccess: () => {
        UserNotification.success('New Investigation created');
        queryClient.invalidateQueries(['get-investigations']);
        forceRefreshDashboard();
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { createInvestigation: mutateAsync, creatingInvestigation: isLoading };
}

export function useUpdateInvestigations() {
  const queryClient = useQueryClient();
  const { forceRefreshDashboard } = useDashboardContext();

  const { mutateAsync, isLoading } = useMutation(
    updateInvestigations,
    {
      onSuccess: (data: UpdateInvestigationResponseAPIType) => {
        if (data.errors.length > 0) {
          const error = { type: 'warning', message: `${data.errors}` };
          UserNotification[error.type](error.message);
        } else {
          UserNotification.success('Investigations updated successfully');
        }

        queryClient.invalidateQueries(['get-investigation-details']);
        queryClient.invalidateQueries(['get-investigations']);
        forceRefreshDashboard();
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    updateInvestigations: mutateAsync,
    updatingInvestigations: isLoading,
  };
}

export function useArchiveInvestigation() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    archiveInvestigation,
    {
      onSuccess: () => {
        UserNotification.success('Investigation archive status updated successfully');
        queryClient.invalidateQueries(['get-investigation-details']);
        queryClient.invalidateQueries(['get-investigations']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    archiveInvestigation: mutateAsync,
    archivingInvestigation: isLoading,
  };
}

export function useBulkArchiveInvestigation() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    bulkArchiveInvestigation,
    {
      onSuccess: () => {
        UserNotification.success('Investigations archive status updated successfully');
        queryClient.invalidateQueries(['get-investigation-details']);
        queryClient.invalidateQueries(['get-investigations']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    bulkArchiveInvestigation: mutateAsync,
    archivingInvestigations: isLoading,
  };
}

export function useDeleteInvestigation() {
  const queryClient = useQueryClient();
  const { forceRefreshDashboard } = useDashboardContext();

  const { mutateAsync, isLoading } = useMutation(
    deleteInvestigation,
    {
      onSuccess: () => {
        UserNotification.success('Investigation deleted successfully');
        queryClient.invalidateQueries(['get-investigations']);
        forceRefreshDashboard();
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    deleteInvestigation: mutateAsync,
    deletingInvestigation: isLoading,
  };
}

export function useBulkDeleteInvestigations() {
  const queryClient = useQueryClient();
  const { forceRefreshDashboard } = useDashboardContext();

  const { mutateAsync, isLoading } = useMutation(
    bulkDeleteInvestigations,
    {
      onSuccess: () => {
        UserNotification.success('Investigations deleted successfully');
        queryClient.invalidateQueries(['get-investigations']);
        forceRefreshDashboard();
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return {
    bulkDelete: mutateAsync,
    bulkDeleting: isLoading,
  };
}

export function useAddEvidence() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addEvidence,
    {
      onSuccess: async (data: UpdateInvestigationResponseAPIType) => {
        if (data.errors.length > 0) {
          const error = { type: 'warning', message: `${data.errors}` };
          UserNotification[error.type](error.message);
        } else {
          UserNotification.success('Evidence added to investigation');
        }

        await new Promise((resolve: (arg?: any) => void) => {
          setTimeout(() => {
            queryClient.invalidateQueries(['get-investigation-details']);
            resolve();
          }, 1000);
        });
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addEvidence: mutateAsync, addingEvidence: isLoading };
}

export function useBulkAddEvidence() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    bulkAddEvidence,
    {
      onSuccess: async (data, variables) => {
        if (data.errors.length > 0) {
          const error = { type: 'warning', message: `${data.errors}` };
          UserNotification[error.type](error.message);
        } else {
          UserNotification.success('Evidence added to investigation');
        }

        await new Promise((resolve: (arg?: any) => void) => {
          setTimeout(() => {
            variables.payload.investigation_ids.forEach((investigation) => queryClient.invalidateQueries(['get-investigation-details', investigation]));
            variables.payload.events.forEach((event) => queryClient.invalidateQueries(['get-security-events', event]));

            resolve();
          }, 1000);
        });
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { bulkAddEvidence: mutateAsync, addingEvidence: isLoading };
}

export function useRemoveEvidence() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    removeEvidence,
    {
      onSuccess: async () => {
        UserNotification.success('Evidence removed from investigation');

        await new Promise((resolve: (arg?: any) => void) => {
          setTimeout(() => {
            queryClient.invalidateQueries(['get-investigation-details']);
            resolve();
          }, 1000);
        });
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { removeEvidence: mutateAsync, removingEvidence: isLoading };
}

export function useAddNote() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addNote,
    {
      onSuccess: () => {
        UserNotification.success('New note added to investigation');
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addNote: mutateAsync, addingNote: isLoading };
}

export function useEditNote() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    editNote,
    {
      onSuccess: () => {
        UserNotification.success('Note edited');
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { editNote: mutateAsync, editingNote: isLoading };
}

export function useDeleteNote() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation(
    deleteNote,
    {
      onSuccess: () => {
        UserNotification.success('Note deleted from investigation');
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deleteNote: mutateAsync };
}

export function useGetPriorities(execute: boolean = true) {
  const { data, isLoading } = useQuery<PriorityAPIType[], Error>(
    ['get-all-priorities'],
    () => defaultOnError(fetchPriorities(), 'Error fetching priorities'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  return {
    priorities: data || [],
    loadingPriorities: isLoading,
  };
}

export function useGetStatuses(execute: boolean = true) {
  const { data, isLoading } = useQuery<StatusAPIType[], Error>(
    ['get-all-statuses'],
    () => defaultOnError(fetchStatuses(), 'Error fetching statuses'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  return {
    statuses: data || [],
    loadingStatuses: isLoading,
  };
}

export function useGetUsers(execute: boolean = true) {
  const { data, isLoading } = useQuery<{ users: UserAPIType[] }, Error>(
    ['get-all-users'],
    () => defaultOnError(fetchUsers(), 'Error fetching users'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  const { users } = data || { users: [] };

  return {
    users: users,
    loadingUsers: isLoading,
  };
}

export function useGetTeams(execute: boolean = true) {
  const { data, isLoading } = useQuery<{ teams: TeamAPIType[] }, Error>(
    ['get-all-teams'],
    () => defaultOnError(fetchTeams(), 'Error fetching teams'),
    {
      retry: 2,
      enabled: execute,
    },
  );

  const { teams } = data || { teams: [] };

  return {
    teams,
    loadingTeams: isLoading,
  };
}

export function useAddPriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addPriority,
    {
      onSuccess: () => {
        UserNotification.success('New priority tag added');
        queryClient.invalidateQueries(['get-all-priorities']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addPriority: mutateAsync, addingPriority: isLoading };
}

export function useUpdatePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updatePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-investigations']);
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updatePriority: mutateAsync, updatingPriority: isLoading };
}

export function useReprioritizePriorities() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    reprioritizePriorities,
    {
      onSuccess: () => {
        UserNotification.success('Priority tags updated');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-investigations']);
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { reprioritizePriorities: mutateAsync, reprioritizingPriorities: isLoading };
}

export function useDeletePriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deletePriority,
    {
      onSuccess: () => {
        UserNotification.success('Priority tag deleted');
        queryClient.invalidateQueries(['get-all-priorities']);
        queryClient.invalidateQueries(['get-investigations']);
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deletePriority: mutateAsync, deletingPriority: isLoading };
}

export function useAddStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    addStatus,
    {
      onSuccess: () => {
        UserNotification.success('New status tag added');
        queryClient.invalidateQueries(['get-all-statuses']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { addStatus: mutateAsync, addingStatus: isLoading };
}

export function useUpdateStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    updateStatus,
    {
      onSuccess: () => {
        UserNotification.success('Status tag updated');
        queryClient.invalidateQueries(['get-all-statuses']);
        queryClient.invalidateQueries(['get-investigations']);
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { updateStatus: mutateAsync, updatingStatus: isLoading };
}

export function useDeleteStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    deleteStatus,
    {
      onSuccess: () => {
        UserNotification.success('Status tag deleted');
        queryClient.invalidateQueries(['get-all-statuses']);
        queryClient.invalidateQueries(['get-investigations']);
        queryClient.invalidateQueries(['get-investigation-details']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { deleteStatus: mutateAsync, deletingStatus: isLoading };
}

export function useSetDefaultPriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    setDefaultPriority,
    {
      onSuccess: () => {
        UserNotification.success('Default priority set');
        queryClient.invalidateQueries(['get-all-priorities']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { setDefault: mutateAsync, settingDefault: isLoading };
}

export function useRemoveDefaultPriority() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    removeDefaultPriority,
    {
      onSuccess: () => {
        UserNotification.success('Default priority removed');
        queryClient.invalidateQueries(['get-all-priorities']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { removeDefault: mutateAsync, removingDefault: isLoading };
}

export function useSetDefaultStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    setDefaultStatus,
    {
      onSuccess: () => {
        UserNotification.success('Default status set');
        queryClient.invalidateQueries(['get-all-statuses']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { setDefault: mutateAsync, settingDefault: isLoading };
}

export function useRemoveDefaultStatus() {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    removeDefaultStatus,
    {
      onSuccess: () => {
        UserNotification.success('Default status removed');
        queryClient.invalidateQueries(['get-all-statuses']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { removeDefault: mutateAsync, removingDefault: isLoading };
}

export function useEnableNotifications() {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(
    enableNotifications,
    {
      onSuccess: (response: EnableNotificationsResponse) => {
        UserNotification.success(`${response.enabled ? 'Enabled' : 'Disabled'} investigation notifications`);
        queryClient.invalidateQueries(['get-investigations-config']);
      },
      onError: (error: Error) => UserNotification.error(error.message),
    },
  );

  return { enableNotifications: mutateAsync, enablingNotifications: isLoading };
}

export function useGetInvestigationsConfig() {
  const { data, isLoading } = useQuery<ConfigApiType, Error>(
    ['get-investigations-config'],
    () => defaultOnError(fetchInvestigationsConfig(), 'Error fetching investigations config'),
    {
      retry: 2,
    },
  );

  return {
    config: data || {
      enable_assignment_notifications: false,
      default_priority: '',
      default_status: '',
    } as ConfigApiType,
    loadingConfig: isLoading,
  };
}
