import * as React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useBulkSetStatusForSecurityEvents } from 'security-app/hooks/useSecurityEventsAPI';
import { useTableFetchContext } from 'components/common/PaginatedEntityTable';
import type { Status } from 'security-app/hooks/api/securityEventsAPI.types';
import { ConfirmDialog } from 'security-app/components/common/Modal';
import SetStatusControl from 'security-app/components/SecurityEvents/Alerts/EventDetails/SetStatusControl';
import HelpBlock from 'components/bootstrap/HelpBlock';
import Alert from 'components/bootstrap/Alert';
import type FetchError from 'logic/errors/FetchError';
import useSendEventActionTelemetry from 'components/events/events/hooks/useSendEventActionTelemetry';

type Props = {
  eventIds: Array<string>,
  onClose: () => void,
  show: boolean,
}
const Container = styled.div`
    width: 25vw;
`;
const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0;
`;

const StyledHelpBlock = styled(HelpBlock)`
  margin: 2px 0;
`;

type SetStatusModalProps = {
  onConfirm: (newStatus: Status) => void,
  show: boolean,
  confirmDisabled: boolean,
  onClose: () => void,
  error: FetchError,
  isError: boolean,
  isLoading: boolean,
}

const SetStatusModal = ({ onConfirm, show, confirmDisabled, onClose, isError, error, isLoading }: SetStatusModalProps) => {
  const [newStatus, setNewStatus] = useState<Status>(undefined);
  const _onConfirm = useCallback(() => onConfirm(newStatus), [newStatus, onConfirm]);

  return (
    <ConfirmDialog show={show}
                   onConfirm={_onConfirm}
                   confirmDisabled={confirmDisabled}
                   confirmText="Assign"
                   onCancel={onClose}
                   maxWidth="fit-content"
                   clipOverflow={false}
                   isLoading={isLoading}
                   title="Select Status">
      <Container>
        <StyledHelpBlock>Please select the new status that should be assigned to all selected events.</StyledHelpBlock>
        {isError ? <Alert bsStyle="danger">{error.message}</Alert> : null}
        <SelectContainer>
          <SetStatusControl width="15vw" onStatusChange={setNewStatus} value={newStatus} />
        </SelectContainer>
      </Container>
    </ConfirmDialog>
  );
};

const SetStatusBulk = ({ eventIds, onClose, show }: Props) => {
  const { setStatus, settingStatus, isError, error } = useBulkSetStatusForSecurityEvents();
  const sendEventActionTelemetry = useSendEventActionTelemetry();
  const { refetch } = useTableFetchContext();

  const onConfirm = useCallback(async (status: Status) => {
    try {
      await setStatus({ ids: eventIds, status });
      onClose();
      refetch();
      sendEventActionTelemetry('SET_STATUS', true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [sendEventActionTelemetry, eventIds, onClose, refetch, setStatus]);

  return show
    ? <SetStatusModal onConfirm={onConfirm} show confirmDisabled={settingStatus} onClose={onClose} error={error} isError={isError} isLoading={settingStatus} />
    : null;
};

export default SetStatusBulk;
