import * as React from 'react';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { SecurityAppSecurityEvents } from '@graylog/enterprise-api';
import { SystemCatalog } from '@graylog/server-api';

import type { FilterComponentProps } from 'stores/PaginationTypes';
import SuggestionsList from 'components/common/EntityFilters/FilterConfiguration/SuggestionsList';
import useCurrentUser from 'hooks/useCurrentUser';

const DEFAULT_SEARCH_PARAMS = {
  query: '',
  pageSize: 10,
  page: 1,
};

const fetchAssignedOwners = () => SecurityAppSecurityEvents.getFilterOptions({
  fields: ['owner'],
  timerange: 30 * 86400,
})
  .then((resp) => {
    const owners = resp.owner;

    return SystemCatalog.getTitles({
      entities: owners.flatMap((owner) => ([{ id: owner, type: 'users' }, { id: owner, type: 'teams' }])),
    });
  })
  .then((resp) => resp.entities.map((entry) => ({ id: entry.id, value: entry.title })));

const OwnerFilter = ({ attribute, allActiveFilters, filter, filterValueRenderer, onSubmit }: FilterComponentProps) => {
  const [, setSearchParams] = useState(DEFAULT_SEARCH_PARAMS);
  const { data: _suggestions, isInitialLoading } = useQuery(['security_events', 'owners'], fetchAssignedOwners);
  const currentUser = useCurrentUser();

  const suggestions = useMemo(() => (_suggestions
    ? [{ id: currentUser.id, value: `Me (${currentUser.fullName})` }, ..._suggestions.filter((suggestion) => suggestion.id !== currentUser.id)]
    : _suggestions), [currentUser, _suggestions]);

  return (
    <SuggestionsList allActiveFilters={allActiveFilters}
                     attribute={attribute}
                     filter={filter}
                     filterValueRenderer={filterValueRenderer}
                     onSubmit={onSubmit}
                     suggestions={suggestions}
                     isLoading={isInitialLoading}
                     total={suggestions?.length}
                     page={1}
                     pageSize={suggestions?.length}
                     setSearchParams={setSearchParams} />
  );
};

export default OwnerFilter;
