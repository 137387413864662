import { qualifyUrls } from 'routing/Routes';

const DataWarehouseRoutes = {
  ARCHIVE: {
    LIST: '/system/data-warehouse',
    show: (archiveId: string) => `/system/data-warehouse/${archiveId}`,
  },
  BACKEND: '/system/data-warehouse/backend',
  CONFIGURATION: '/system/data-warehouse/configuration',
  PREVIEW: '/data-storage/preview',
} as const;
const routes = qualifyUrls(DataWarehouseRoutes);

export default {
  ...routes,
  unqualified: DataWarehouseRoutes,
};
