import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { Spinner, Icon } from 'components/common';

import Modal from './Modal';

const StyledIcon = styled(Icon)`
  padding-right: 0.5rem;
`;

type ButtonsProps = {
  onConfirm: () => void;
  onCancel: () => void;
  noCancelButton: boolean,
  confirmText: React.ReactNode;
  confirmDisabled: boolean;
  isLoading: boolean;
};

function Buttons({ onConfirm, onCancel, noCancelButton, confirmText, confirmDisabled, isLoading }: ButtonsProps) {
  return (
    <>
      {noCancelButton || <Button onClick={onCancel}>Cancel</Button>}
      <Button bsStyle="success"
              onClick={onConfirm}
              disabled={confirmDisabled || isLoading}>
        {isLoading && <Spinner text="" />} {confirmText}
      </Button>
    </>
  );
}

type DialogProps = {
  show: boolean,
  title: string | React.ReactNode,
  type?: 'danger' | 'warning' | 'info' | 'success' | 'default',
  onConfirm: () => void,
  onCancel?: () => void,
  noCancelButton?: boolean,
  confirmText?: React.ReactNode,
  confirmDisabled?: boolean,
  isLoading?: boolean,
  children: React.ReactNode,
  maxWidth?: string,
  clipOverflow?: boolean,
};

function ConfirmDialog({
  show,
  title,
  type = 'default',
  onConfirm,
  onCancel = () => {},
  noCancelButton = false,
  confirmText = 'Confirm',
  confirmDisabled = false,
  isLoading = false,
  children,
  maxWidth = undefined,
  clipOverflow = undefined,
}: DialogProps) {
  const handleClose = React.useCallback(() => {
    if (noCancelButton) onConfirm();
    onCancel();
  }, [onCancel, onConfirm, noCancelButton]);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Enter' && show) onConfirm();
    };

    document.addEventListener('keydown', listener, true);

    return () => {
      document.removeEventListener('keydown', listener, true);
    };
  }, [show, onConfirm]);

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === 'Escape' && show) handleClose();
    };

    document.addEventListener('keydown', listener, true);

    return () => {
      document.removeEventListener('keydown', listener, true);
    };
  }, [show, handleClose]);

  const titleByType = {
    danger: <span><StyledIcon className="text-danger" name="cancel" /> {title}</span>,
    warning: <span><StyledIcon className="text-warning" name="warning" /> {title}</span>,
    info: <span><StyledIcon className="text-info" name="error" /> {title}</span>,
    success: <span><StyledIcon className="text-success" name="exclamation" /> {title}</span>,
    default: title,
  };

  return (
    <Modal show={show}
           title={titleByType[type]}
           onClose={handleClose}
           closeOnBackdrop
           confirmDialog
           aria-label="confirm dialog"
           maxWidth={maxWidth}
           clipOverflow={clipOverflow}
           buttons={(
             <Buttons onConfirm={onConfirm}
                      onCancel={handleClose}
                      noCancelButton={noCancelButton}
                      confirmText={confirmText}
                      confirmDisabled={confirmDisabled}
                      isLoading={isLoading} />
           )}>
      {children}
    </Modal>
  );
}

export default ConfirmDialog;
