import * as React from 'react';
import styled, { css } from 'styled-components';

import FieldTypeIcon from 'views/components/sidebar/fields/FieldTypeIcon';
import type FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import FieldType from 'views/logic/fieldtypes/FieldType';

const Row = styled.div(({ theme }) => css`
  display: flex;
  border-bottom: 1px solid ${theme.colors.gray[80]};
  min-width: fit-content;
  word-break: break-word;
  overflow-wrap: break-word;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${theme.colors.table.row.backgroundHover};
    color: ${theme.utils.contrastingColor(theme.colors.table.row.backgroundHover)};
  }
`);

const Type = styled.div`
  padding: 3px 5px;
  flex: 0.2;
  min-width: 35px;
`;

const FieldContainer = styled.div(({ theme }) => css`
  font-family: ${theme.fonts.family.body};
  padding: 3px 5px;
  flex: 1;
  min-width: 140px;
`);

const ValueContainer = styled.div(({ theme }) => css`
  padding: 3px 5px;
  flex: 4;
  font-family: ${theme.fonts.family.monospace};
  min-width: 140px;
  white-space: pre-wrap;
`);

const Selection = styled.div`
  padding: 3px 5px;
  flex: 0.1;
  min-width: 25px;
`;

const HeadRow = styled.div(({ theme }) => css`
  font-family: ${theme.fonts.family.body};
  display: flex;
  min-width: fit-content;
`);

const HeadValue = styled.div`
  padding: 3px 5px;
  flex: 4;
  min-width: 140px;
`;

type HeadRowProps = {
  onSelectAll: () => void,
  allSelected: () => boolean,
  hideCheckbox: boolean,
};

export const LogViewMessageDetailsHeadRow = ({ onSelectAll, allSelected, hideCheckbox }: HeadRowProps) => (
  <HeadRow>
    {!hideCheckbox && (
    <Selection>
      <input title="Select all" type="checkbox" onChange={onSelectAll} checked={allSelected()} />
    </Selection>
    )}
    <Type>Type</Type>
    <FieldContainer>Field</FieldContainer>
    <HeadValue>Value</HeadValue>
  </HeadRow>
);

type Props = {
  field: string,
  hideCheckbox: boolean,
  onSelect: () => void,
  renderFieldName?: (field: string, fieldType: FieldType) => React.ReactElement,
  renderFieldValue?: (field: string, fieldType: FieldType, value: string) => React.ReactElement,
  selected: boolean,
  type: FieldTypeMapping,
  value: string,
};

const LogViewMessageDetailsRow = ({
  field, value, type, onSelect, selected,
  hideCheckbox, renderFieldName = (fieldName: string) => <>{fieldName}</>,
  renderFieldValue = (_fieldName: string, _fieldType: FieldType, fieldValue: string) => <>{fieldValue}</>,
}: Props) => {
  const fieldType = type?.type ?? FieldType.Unknown;

  return (
    <Row key={field}>
      {!hideCheckbox && (
        <Selection><input title={`${selected ? 'Remove from' : 'Add to'} table`}
                          onChange={onSelect}
                          type="checkbox"
                          checked={selected} />
        </Selection>
      )}
      <Type><FieldTypeIcon type={fieldType} /></Type>
      <FieldContainer>
        {renderFieldName(field, fieldType)}
      </FieldContainer>
      <ValueContainer>
        {renderFieldValue(field, fieldType, value)}
      </ValueContainer>
    </Row>
  );
};

export default LogViewMessageDetailsRow;
