import * as React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

import { Button } from 'components/bootstrap';
import { Icon, SourceCodeEditor } from 'components/common';

import MDPreview from './MDPreview';

const Backdrop = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  inset: 0;
  z-index: 1051;

  background-color: rgb(0 0 0 / 50%);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75vw;
  max-width: 1000px;
  height: 65vh;
  top: 10vh;
  padding: 16px;

  background-color: ${({ theme }) => theme.colors.global.contentBackground};
  border: 1px solid ${({ theme }) => theme.colors.input.border};
  border-radius: 8px;
`;

const CloseIcon = styled(Icon)`
  margin-left: auto;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.input.placeholder};

  &:hover {
    color: ${({ theme }) => theme.colors.global.textDefault};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  gap: 1rem;

  &#editor-body {
    flex-grow: 1;
  }
`;

const EditorWrapper = styled.div`
  .ace_editor {
    border: 1px solid ${({ theme }) => theme.colors.input.border} !important;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

type Props = {
  value: string;
  readOnly?: boolean;
  show: boolean;
  helpBlock?: React.ReactNode,
  onChange: (newValue: string) => void;
  onClose: () => void;
  onDone?: (newValue?: string) => void;
}

function MDEditorFullView({ value, readOnly = false, onChange, show, onClose, onDone = () => {}, helpBlock = undefined }: Props) {
  const [height, setHeight] = React.useState<number>(0);
  const [localValue, setLocalValue] = React.useState<string>(value);

  React.useEffect(() => {
    const contentHeight = document.getElementById('editor-body')?.scrollHeight;
    setHeight(contentHeight);
  }, []);

  React.useEffect(() => setLocalValue(value), [value]);

  const handleOnChange = React.useCallback((newValue: string) => {
    setLocalValue(newValue);
    onChange(newValue);
  }, [onChange]);

  const handleOnDone = React.useCallback(() => {
    onDone?.(localValue);
    onClose();
  }, [localValue, onClose, onDone]);

  const Component = React.useMemo(() => (
    show ? (
      <Backdrop onClick={() => onClose()}>
        <Content onClick={(e: React.BaseSyntheticEvent) => e.stopPropagation()}>
          <Row>
            <Title>Markdown Editor</Title>
            <CloseIcon name="close" onClick={() => onClose()} />
          </Row>
          <Row>
            {helpBlock}
          </Row>
          <Row id="editor-body">
            {height > 0 && (
              <>
                <EditorWrapper style={{ width: '50%' }}>
                  <SourceCodeEditor id="md-editor"
                                    mode="markdown"
                                    theme="light"
                                    toolbar={false}
                                    resizable={false}
                                    readOnly={readOnly}
                                    height={height}
                                    value={localValue}
                                    onChange={handleOnChange} />
                </EditorWrapper>
                <div style={{ width: '50%' }}>
                  <MDPreview show value={localValue} height={height} />
                </div>
              </>
            )}
          </Row>
          <Row style={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button onClick={() => onClose()}>Cancel</Button>
            <Button bsStyle="success" onClick={handleOnDone}>Done</Button>
          </Row>
        </Content>
      </Backdrop>
    ) : null
  ), [show, helpBlock, height, readOnly, localValue, handleOnChange, handleOnDone, onClose]);

  return <>{ReactDom.createPortal(Component, document.body)}</>;
}

export default MDEditorFullView;
