import * as React from 'react';
import type * as Immutable from 'immutable';
import styled, { css } from 'styled-components';

import { CELL_SEP } from 'logview/Constants';
import { TableHead } from 'views/components/datatable';

import LogViewCell from './LogViewCell';

const LogViewTableHead = styled(TableHead)(({ theme }) => css`
  color-adjust: exact !important; /* Needed for report generation */
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${theme.colors.gray[90]};
    color: initial;
  }
`);

const InnerContainer = styled.div(({ theme }) => css`
  padding: 3px 5px;
  display: flex;
  min-width: fit-content;
  background-color: ${theme.colors.table.head.background};
  gap: ${theme.spacings.xxs};
`);

type Props = {
  columns: Immutable.Set<string>
}

const LogViewHeader = ({ columns }: Props) => (
  <LogViewTableHead as="div">
    <InnerContainer>
      {columns.toArray().map((fieldName) => (
        <React.Fragment key={`table-header-cell-${fieldName}`}>
          <LogViewCell fieldName={fieldName} role="columnheader">
            {fieldName}
          </LogViewCell>
          {fieldName !== columns.last() ? CELL_SEP : null}
        </React.Fragment>
      ))}
    </InnerContainer>
  </LogViewTableHead>
);

export default LogViewHeader;
