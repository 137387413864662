import * as React from 'react';
import styled, { css } from 'styled-components';

import { ControlLabel } from 'components/bootstrap';
import type { Status } from 'security-app/hooks/api/securityEventsAPI.types';
import { SECURITY_EVENT_STATUSES } from 'security-app/hooks/api/securityEventsAPI.types';
import { Select } from 'components/common';

const statusesOptions = Object.entries(SECURITY_EVENT_STATUSES)
  .map(([value, label]) => ({ label, value }));

const StyledSelect = styled(Select)<{ $width: string }>(({ $width }) => css`
    width: ${$width};
    cursor: pointer;
`);

const StyledControlLabel = styled(ControlLabel)`
  margin: 0;
`;

type Props = {
  disabled?: boolean,
  onStatusChange: (newStatus: Status) => void,
  value: Status,
  width?: string
}
const SetStatusControl = ({ disabled = false, onStatusChange, value, width = '150px' }: Props) => (
  <>
    <StyledControlLabel>Status</StyledControlLabel>
    <StyledSelect $width={width}
                  options={statusesOptions}
                  value={value}
                  clearable={false}
                  disabled={disabled}
                  onChange={onStatusChange} />
  </>
);

export default SetStatusControl;
