import __request__ from 'routing/request';
type PackRecordArray = PackRecord[];
interface IlluminateInstallationStatus {
    readonly illuminate_version: string;
    readonly updated_at: string;
    readonly disable_packs: boolean;
    readonly spotlight_pack_titles: string[];
    readonly install_in_progress: boolean;
    readonly pack_titles: string[];
    readonly created_at: string;
    readonly id: string;
    readonly install_progress: ProgressStep[];
    readonly previous_install: PreviousInstall;
}
type BundleRecordArray = BundleRecord[];
interface PreviousInstall {
    readonly illuminate_version: string;
    readonly disable_packs: boolean;
    readonly installed_at: string;
    readonly spotlight_pack_titles: string[];
    readonly pack_titles: string[];
    readonly description: string;
    readonly title: string;
    readonly successful: boolean;
}
interface SpotlightPackRecord {
    readonly is_core: boolean;
    readonly requirements: string[];
    readonly release_notes: string;
    readonly pack_id: string;
    readonly input_types: string[];
    readonly description: string;
    readonly content_pack_id: string;
    readonly title: string;
    readonly version: unknown;
    readonly enabled: boolean;
    readonly tags: string[];
    readonly license: string[];
    readonly pack_path: string;
    readonly version_requirements: string[];
    readonly category: string;
    readonly included_in_bundle: boolean;
}
interface IlluminateStatus {
    readonly deprecated_illuminate_enabled: boolean;
    readonly upload_enabled: boolean;
    readonly illuminate_enabled: boolean;
}
interface BundleRecord {
    readonly spotlight_packs: SpotlightPackRecord[];
    readonly previous: boolean;
    readonly file_id: string;
    readonly bundle_id: string;
    readonly cached: boolean;
    readonly executed_index_updates: string[];
    readonly description: string;
    readonly id: string;
    readonly title: string;
    readonly packs: ProcessingPackRecord[];
    readonly version: string;
    readonly enabled: boolean;
}
interface ProcessingPackRecord {
    readonly is_core: boolean;
    readonly requirements: string[];
    readonly release_notes: string;
    readonly pack_id: string;
    readonly input_types: string[];
    readonly description: string;
    readonly title: string;
    readonly version: unknown;
    readonly enabled: boolean;
    readonly tags: string[];
    readonly license: string[];
    readonly version_requirements: string[];
    readonly required_indices: string[];
    readonly category: string;
    readonly included_in_bundle: boolean;
}
interface ProgressStep {
    readonly install_in_progress: boolean;
    readonly description: string;
    readonly STEP: 'PAUSE_PROCESSING' | 'FLUSH_OUTPUT_BUFFER' | 'ACTIVATE_BUNDLE' | 'INSTALL_INDICES' | 'ROTATING_INDICES' | 'INSTALL_SPOTLIGHTS' | 'RESTART_ILLUMINATE_PROCESSOR' | 'RESUME_MESSAGE_PROCESSING';
    readonly complete: boolean;
    readonly title: string;
    readonly successful: boolean;
}
interface FilterOptionsRequest {
    readonly fields: string[];
}
interface PacksRequest {
    readonly processing_pack_ids: string[];
    readonly spotlight_pack_ids: string[];
}
interface PreviousBundleResponse {
    readonly version: string;
}
interface PackRecord {
    readonly is_core: boolean;
    readonly requirements: string[];
    readonly release_notes: string;
    readonly pack_id: string;
    readonly input_types: string[];
    readonly is_new: boolean;
    readonly description: string;
    readonly type: string;
    readonly title: string;
    readonly enabled: boolean;
    readonly tags: string[];
    readonly license: string[];
    readonly is_compatible: boolean;
    readonly version_requirements: string[];
    readonly category: string;
    readonly update_available: boolean;
    readonly included_in_bundle: boolean;
}
interface PackFilterOptions {
    readonly input_types: string[];
    readonly category: string[];
    readonly tags: string[];
}
interface LatestSummaryResponse {
    readonly new_packs_available: boolean;
    readonly version: string;
    readonly updated_packs_available: boolean;
}
type stringArray = string[];
interface InstallBundleRequest {
    readonly spotlightPackIds: string[];
    readonly processingPackIds: string[];
    readonly enabled: boolean;
}
interface PaginatedResponse_PackRecord {
    readonly [_key: string]: Object;
}
/**
 * List all Illuminate bundles
 */
export function listBundles(): Promise<BundleRecordArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the status of the current Illuminate installation.
 */
export function getInstallationStatus(): Promise<IlluminateInstallationStatus> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/installation_status', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Disable Processing and Spotlight packs. 
 */
export function disableCurrentPacks(request: PacksRequest): Promise<PackRecordArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/bundles/latest/disable_packs', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enable or disable Processing and Spotlight packs. Upgrades to latest cached bundle if available.
 */
export function enableCurrentPacks(request: PacksRequest): Promise<PackRecordArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/bundles/latest/enable_packs', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Filter installed packs based on title, category, subcategory, and tags.
 */
export function filterPacks(query?: string, category?: stringArray, tags?: stringArray, is_new?: boolean, update_available?: boolean, type?: 'PROCESSING' | 'SPOTLIGHT', include_deps: boolean = false, include_core: boolean = false): Promise<PackRecordArray> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/latest/search_packs', null, { 'query': query, 'category': category, 'tags': tags, 'is_new': is_new, 'update_available': update_available, 'type': type, 'include_deps': include_deps, 'include_core': include_core }, {
        'Accept': ['application/json']
    });
}
/**
 * Search packs for the latest bundle.
 */
export function searchPacksPaginated(page: number = 1, per_page: number = 15, query?: string, category?: stringArray, tags?: stringArray, is_new?: boolean, update_available?: boolean, type?: 'PROCESSING' | 'SPOTLIGHT', include_deps: boolean = false): Promise<PaginatedResponse_PackRecord> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/latest/search_packs_paginated', null, { 'page': page, 'per_page': per_page, 'query': query, 'category': category, 'tags': tags, 'is_new': is_new, 'update_available': update_available, 'type': type, 'include_deps': include_deps }, {
        'Accept': ['application/json']
    });
}
/**
 * Contents summary for the latest bundle.
 */
export function latestSummary(): Promise<LatestSummaryResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/latest/summary', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all possible categories for Illuminate packs
 */
export function packFilterOptions(request: FilterOptionsRequest): Promise<PackFilterOptions> {
    return __request__('PUT', '/plugins/org.graylog.plugins.illuminate/bundles/packs/filter_options', request, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Check if previously installed Illuminate version exists.
 */
export function previousBundleExists(): Promise<PreviousBundleResponse> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/previous', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Enable previously installed Illuminate version.
 */
export function enablePreviousBundle(): Promise<PackRecordArray> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/bundles/previous/enable_bundle', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Reload and restart Illuminate processing on the current node.
 */
export function restartProcessing(): Promise<void> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/bundles/processing_restart', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get the status of deprecated and current Illuminate installations
 */
export function getStatus(): Promise<IlluminateStatus> {
    return __request__('GET', '/plugins/org.graylog.plugins.illuminate/bundles/status', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Upload Illuminate bundle
 */
export function upload(): Promise<string> {
    return __request__('POST', '/plugins/org.graylog.plugins.illuminate/bundles/upload', null, {}, {
        'Accept': ['text/plain']
    });
}
/**
 * Enable bundle
 */
export function enableBundle(version: string): Promise<void> {
    return __request__('POST', `/plugins/org.graylog.plugins.illuminate/bundles/${version}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Delete a disabled Illuminate bundle
 */
export function remove(version: string): Promise<void> {
    return __request__('DELETE', `/plugins/org.graylog.plugins.illuminate/bundles/${version}`, null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * List all or search bundle packs
 */
export function listPacks(version: string, page: number = 1, per_page: number = 15, query?: string, include_deps: boolean = false): Promise<PaginatedResponse_PackRecord> {
    return __request__('GET', `/plugins/org.graylog.plugins.illuminate/bundles/${version}/packs`, null, { 'page': page, 'per_page': per_page, 'query': query, 'include_deps': include_deps }, {
        'Accept': ['application/json']
    });
}
/**
 * Enable or disable Processing and Spotlight packs
 */
export function managePacks(version: string, request: InstallBundleRequest): Promise<PackRecordArray> {
    return __request__('POST', `/plugins/org.graylog.plugins.illuminate/bundles/${version}/packs`, request, {}, {
        'Accept': ['application/json']
    });
}
