import { useEffect } from 'react';

const useOnBrowserNavigation = (fn: () => void) => {
  useEffect(() => {
    window.addEventListener('popstate', fn);

    return () => {
      window.removeEventListener('popstate', fn);
    };
  }, [fn]);
};

export default useOnBrowserNavigation;
