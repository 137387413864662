import __request__ from 'routing/request';
interface BulkSecurityEventNotificationRequest {
    readonly event_ids: string[];
    readonly notification_id: string;
}
interface SecurityEventNotificationResultMap {
    readonly [_key: string]: SecurityEventNotificationResult;
}
interface SecurityEventNotificationResponse {
    readonly messages: string[];
}
interface SecurityEventNotificationResult {
    readonly response: SecurityEventNotificationResponse;
    readonly error: string;
    readonly status: 'OK' | 'FAILURE';
}
/**
 * Trigger notifications for multiple existing events
 */
export function executeForEvents(JSONBody?: BulkSecurityEventNotificationRequest): Promise<SecurityEventNotificationResultMap> {
    return __request__('POST', '/plugins/org.graylog.plugins.securityapp/security_events/notifications', JSONBody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Trigger an alert for an existing event
 */
export function executeForEvent(eventId?: string, notificationId?: string): Promise<SecurityEventNotificationResponse> {
    return __request__('POST', `/plugins/org.graylog.plugins.securityapp/security_events/notifications/${eventId}/${notificationId}`, null, {}, {
        'Accept': ['application/json']
    });
}
